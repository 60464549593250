<template>
    <div class="container">
        <Header></Header>
        <section>
            <img src="../assets/icon/top.png" class="blog_banner" alt="">
            <div class="bg ">
                <div class="w1100 pt40 pr lh18">
                    <div>
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">PPF & Window Film </router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Product Information</div>
                    <div class="tab_nav mt50 m-table">
                        <div class="tab_item" v-for="(item, index) in tab" :key="index">
                            <div class="content flex-box flex-center">
                                <span class="tag">{{ item.tag }}</span>
                                <p class=" right fwb"><span>{{ item.title }}</span></p>
                            </div>
                            <template v-if="item.list.length > 0">
                                <ul class="list">
                                    <li v-for="(items, indexs) in item.list" :key="indexs"><router-link>{{ items
                                            }}</router-link></li>
                                </ul>
                            </template>
                        </div>

                    </div>
                    <div class="mt70 flex-box info pb100">
                        <div class="cover">
                            <img src="../assets/icon/new_cover.png" alt="">
                        </div>
                        <div class="flex-grow-1 pl70 content">
                            <p class="fwb">Versatile<br>Series</p>
                            <div>Versatile Series features high-quality cost-efficient products designed for diverse
                                applications and
                                environments, seamlessly adapting to your evolving needs.</div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiGetPPFDetail } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
const router = useRouter();
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let info = ref('Joyvie PPF features exclusive Multi-Functional\nTopCoat technology providing self-heading.stain,\nand corrosion resistance,extending the films lifespan\nand maintaining a glossy appearance.')
let nindex = ref(-1)
const tab = ref([{
    tag: 'J',
    title: 'Jewel\nSeries',
    list: []
}, {
    tag: 'O',
    title: 'Outstanding \n Series',
    list: ['O8', 'O7', 'O7M']
}, {
    tag: 'Y',
    title: 'Yielding\n Series',
    list: []
}, {
    tag: 'V',
    title: 'Versatile \n Series',
    list: ['V8', 'V7', 'V7S']
}, {
    tag: 'I',
    title: 'Inspiration\nSeries',
    list: []
}, {
    tag: 'E',
    title: 'Exclusive\nSeries',
    list: ['ECO']
},])
const chooseItem = (i) => {
    if (features.value.includes(i)) {
        features.value.pop(i)
    } else {
        features.value.push(i)
    }
}
const detail = ref(null);
const features = ref([]);
onBeforeMount(() => {
    //getInfos()
})
const params = router.currentRoute.value.query;
useHead({
    title: 'PPF_JOYVIE',
    meta: [
        {
            name: "description",
            content: 'PPF_JOYVIE',
        },
        {
            name: "keywords",
            content: 'PPF_JOYVIE',
        },
    ],
});

const id = ref(0);
if (params.id) {
    id.value = params.id;
}
const getInfos = async () => {
    try {

        console.log(id.value)
        const res = await apiGetPPFDetail({ id: id.value }); // 调用接口
        if (res.code != 1) {
            return
        }

        detail.value = res.data;


        useHead({
            title: res.data.sub_title,
            meta: [
                {
                    name: "description",
                    content: res.data.desc,
                },
                {
                    name: "keywords",
                    content: res.data.keywords,
                },
            ],
        });
    } catch (error) {
        console.error(error);
    }
}



</script>
<style lang="less" scoped>
.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.before_link {

    a {
        color: #152F4A;
    }
}
.before_link:hover a{
  color: #FB471F;
}
.w1100 {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.bg {
    width: 100%;
    background-image: url('../assets/icon/bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat-y;
}

.pc {
    height: auto;
    overflow: hidden;
}

.mobile {
    height: 0;
    overflow: auto;
}

.tab_nav {
    .tab_item {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        width: 175px;
        background: #C0C6C8;
        border-radius: 36px;
        cursor: pointer;
        position: relative;

        &:nth-of-type(6n) {
            margin-right: 0;
        }

        .list {
            display: none;
        }

        .content {
            width: 175px;
            height: 73px;
            position: relative;
            z-index: 2;

            img {

                width: 31px;
                height: 31px;
                object-fit: cover;
                display: block;
            }

            .right {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #152F4A;
                height: 29px;
                line-height: 17px;
                color: #152F4A;
            }
        }

        &:hover {
            background: #FFFFFF;

        }

        &:hover .list {
            display: block;
            padding-left: 35px;
            padding-right: 20px;
            padding-bottom: 25px;
            padding-top: 5px;
            position: absolute;
            top: 73px;
            left: 0;
            right: 0;
            background: #FFFFFF;
            border-radius: 0 0 36px 36px;
        }

        &:hover .list::before {
            content: '';
            background: #FFFFFF;
            top: -36px;
            width: 100%;
            left: 0;
            height: 36px;
            position: absolute;
            z-index: 1;
        }

        li {

            position: relative;

            a {
                display: inline-block;
                border-bottom: 1px solid #152F4A;
                line-height: 22px;
                padding-right: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        li:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 3px;
            height: 3px;
            background: #152F4A;
            border-radius: 2px;

        }

        .tag {
            width: 31px;
            display: block;
            line-height: 31px;
            text-align: center;
            font-size: 25px;
            color: #FFFFFF;
            background: #152F4A;
            border-radius: 50%;
            font-family: 'Metropolis-SemiBold';
        }
    }
}

.cover {
    width: 504px;
    height: 582px;
    border-radius: 23px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 23px;
        object-fit: cover;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.info {
    p {
        font-size: 82px;
        color: #152F4A;
        line-height: 90px;
    }

    div {
        margin-top: 75px;
        font-weight: 400;
        font-size: 27px;
        color: #152F4A;
        line-height: 40px;
    }
}

.pb100 {
    padding-bottom: 145px;
}
</style>
<style></style>
