<!--
 * @author: JJ
 * @since: 2024-11-08
 * Plotter.vue
-->
<template>
  <div class="container" v-if="detail">
    <Header />
    <section>
      <swiper ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }"
        :autoplay="{ delay: 3000, disableOnInteraction: false }" :modules="modules" class="blog_swiper product_s" loop>
        <swiper-slide v-for="(items, index) in detail.images_arr">
          <img :src="items" class="blog_banner" alt="">
        </swiper-slide>

      </swiper>
      <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr fs14 lh18">
        <div class="before_link"> <router-link to="/">Home /</router-link></div>
        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
     <div class="now_link">  <router-link to="/Plotter">Plotter  </router-link> </div>

      </div>
      <div class="w1270 pt65 pb80">
        <p class="fwb fs40 col1 lh40 pb10">Plotter</p>
        <ul class="flex flex-start flex-wrap flex-between">
          <li class="plotter_item col1 tc" v-for="(items, index) in detail.label_arr">
            <img :src="items.image" alt="" class="animate__animated">
            <p class="fw500 fs24 lh24">{{ items.title }}</p>
            <div class="fs16 lh20">{{ items.content }}</div>
          </li>

        </ul>
      </div>
      <div class="model_nav">
        <div class="title_nav flex-box fwb fs40 col1">
          <img src="../assets/icon/icon_x.png" alt="">
          <div>Comparison of {{ detail.count }} Plotter Models</div>
        </div>
        <ul class="w1270">
          <li class="model_item" v-for="(item, index) in detail.goods" :key="index">
            <div class="img_nav">
              <img :src="item.goods_image" alt="">
            </div>
            <div class="pt30 plr29 col1">
              <h1 class="fw500 fs24 lh24 pb10">{{ item.goods_name }}</h1>
              <div class="flex-box info">
                <div class="fw500 fs18 flex-grow-1 pr40">{{ item.useage_time }}</div>
                <div class="fs14 col89">Usage Time</div>
              </div>
              <div class="flex-box info">
                <div class="fw500 fs18 flex-grow-1 pr40">{{ item.plotting }}</div>
                <div class="fs14 col89">Plotting</div>
              </div>
              <div class="flex-box info">
                <div class="flex-grow-1 pr40" v-if="item.puncture_resistance == 1 || item.puncture_resistance == '1'">
                  <img src="../assets/icon/icon_choose_d.png" alt="">
                </div>
                <div class="fs14 col89">Puncture Resistance</div>
              </div>
              <div class="flex info">
                <div class="fw500 fs18 pr40">{{ item.puncture_suopport }}</div>
                <div class="fs14 col89 flex-grow-1 tr">Puncture suopport</div>
              </div>
              <router-link class="more" :to="{ name: 'PlotterInfo', query: { id: item.id } }">Learn More+</router-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- <img :src="detail.mini_image ? detail.mini_image : '../assets/icon/plotter_bottom.png'" class="bottom_banner" -->
        <!-- alt=""> -->
         <img src="../assets/icon/plotter_bottom.png" class="bottom_banner"
        alt="">
    </section>
    <Footer />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiArticle } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'swiper/css/effect-creative';
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
const router = useRouter()
const a = ref(0)
const detail = ref(null);
onBeforeMount(() => {
  getInfos()
})


const getInfos = async () => {
  try {

    const res = await apiArticle({ title: 'Plotter' }); // 调用接口

    detail.value = res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.pc {
  height: auto;
  overflow: auto;
}

.mobile {
  height: 0;
  overflow: hidden;
}

.blog_swiper {
  width: 100%;
  height: calc(100vw / 1440 * 400);
}

.blog_banner {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.watermark {
  width: 704px;
  height: auto;
  display: block;
}

.mt_10 {
  margin-top: -10px;
}

.before_link {
  color: #898989;
  display: inline-block;
}

.now_link {
  color: #555;
  display: inline-block;
}

.plotter_item {
  width: 392px;
  margin-top: 60px;
 &:hover img{
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
  img {
    width: 74px;
    height: auto;
  }

  p {
    margin-top: 30px;
    font-family: Metropolis-Medium;
  }

  div {
    margin-top: 15px;
  }
}

.model_nav {
  width: 100%;
  background: #F3F7FF;
  padding-top: 70px;
  padding-bottom: 80px;

  .title_nav {
    padding-bottom: 30px;

    img {
      width: 115px;
      height: auto;
      margin-right: 20px;
    }
  }

  .model_item {
    display: inline-block;
    vertical-align: top;
    margin-top: 30px;
    width: 295px;
    padding-bottom: 22px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #122942;
    margin-right: 30px;
    transition: all 0.6s;

    .img_nav {
      width: 100%;
      height: 190px;
      background: #F3F7FF;
      border-radius: 8px 8px 0px 0px;
      padding: 28px 25px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .info {
      padding: 20px 0;
      border-bottom: 1px dotted #EEEEEE;

      img {
        width: 18px;
        height: auto;
      }
    }
  }

  .model_item:hover {
    transform: translateY(-10px);
    box-shadow: 0 1px 6px 0 rgba(184, 189, 202, 1);
  }

  .model_item:nth-of-type(4n) {
    margin-right: 0;
  }

  .more {
    width: 121px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    background: #FB471F;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: block;
  }
  .more:hover{font-size: 16px;}
}

.plr29 {
  padding-left: 29px;
  padding-right: 29px;
}

.w132 {
  min-width: 132px;
}

.bottom_banner {
  width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 1280px) {
  .model_nav {
    .model_item {
      margin-right: 15px;
    }

    .model_item:nth-of-type(4n) {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 980px) {
  .mobile {
    height: auto;
    overflow: hidden;
  }

  .pc {
    height: 0;
    overflow: auto;
  }

  .watermark {
    width: calc(100% / 1440 * 704);
    height: auto;
    display: block;
  }

  .mt_10 {
    margin-top: 10px;
  }

  .plotter_item {
    width: 100%;
    margin-top: 60px;
  }

  .model_nav {
    .model_item {
      margin-right: auto;
      margin-left: auto;
      display: block;
    }

    .model_item:nth-of-type(4n) {
      margin-right: auto;
    }
  }
}
</style>
