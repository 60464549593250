<template>
  <nav>
  </nav>
  <RouterView v-if="isReload" :key="route.fullPath"/>

  <Fixed />
</template>
<script setup>


import { ref, provide, nextTick } from 'vue'
import { useRouter } from 'vue-router';
const route = useRouter();
const isRouterActive = ref(true)
const isReload = ref(true)
const reload = () => {
  isReload.value = false
  nextTick(() => {
    isReload.value = true
  })
}
provide('Reload', reload)
// provide('reload', () => {
//   isRouterActive.value = false
//   nextTick(() => {
//     isRouterActive.value = true
//   })
// })
// app.use(function(req, res, next) {
//   res.header("Access-Control-Allow-Origin", "*"); // 或者指定特定的域名
//   res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
//   next();
// });

</script>
<style lang="less">
  @import url('./assets/css/dot.css');
</style>
