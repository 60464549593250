<!--
 * @author: JJ
 * @since: 2024-12-02
 * Warrantylist.vue
-->
<template>
    <div>
        <Header></Header>
        <section class="container">
            <img src="../assets/icon/warranty_top_bg.png" class="blog_banner" alt="">
            <div class="main pc">
                <ul class="w1270 m-table">
                    <li class="item" v-for="(item, index) in 7" :key="index">
                        <div class="flex-box flex-between">
                            <span class="type fs18 fwb">PPF</span>
                            <router-link to="" class="semibold more">MORE</router-link>
                        </div>
                        <div class="mt10 flex">
                            <div class="cover">
                                <img src="../assets/icon/blog_news_cover.png" alt="">
                            </div>
                            <div class="flex-grow-1 pl25">
                                <p class="m-ellipsis col152 fwb">V7</p>
                                <p class="m-ellipsis col152 fwb">Full Car</p>
                                <p class="m-ellipsis colea mt15 semibold">BMW X1 2024</p>
                                <ul class="m-table item_ul">
                                    <li class="m-ellipsis">Shop:</li>
                                    <li class="m-ellipsis">Nevada Robinson</li>
                                    <li class="m-ellipsis">Date of Installation:</li>
                                    <li class="m-ellipsis">Nov. 28, 2024</li>
                                    <li class="m-ellipsis">Roll Serial No:</li>
                                    <li class="m-ellipsis">SDAS213123141</li>
                                    <li class="m-ellipsis">Warranty ID:</li>
                                    <li class="m-ellipsis">QWEQWEffa2312</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="main mobile">
                <ul class="w1270 m-table">
                    <li class="item" v-for="(item, index) in 7" :key="index">
                        <div class="flex-box flex-between">
                            <span class="type fs18 fwb">PPF</span>
                            <router-link to="" class="semibold more">MORE</router-link>
                        </div>
                        <div class="mt15">
                            <div class="cover">
                                <img src="../assets/icon/blog_news_cover.png" alt="">
                            </div>
                            <div class="mt10">
                                <p class="m-ellipsis col152 fwb">V7</p>
                                <p class="m-ellipsis col152 fwb">Full Car</p>
                                <p class="m-ellipsis colea mt10 semibold">BMW X1 2024</p>
                                <ul class="m-table item_ul">
                                    <li class="m-ellipsis">Shop:</li>
                                    <li class="m-ellipsis">Nevada Robinson</li>
                                    <li class="m-ellipsis">Date of Installation:</li>
                                    <li class="m-ellipsis">Nov. 28, 2024</li>
                                    <li class="m-ellipsis">Roll Serial No:</li>
                                    <li class="m-ellipsis">SDAS213123141</li>
                                    <li class="m-ellipsis">Warranty ID:</li>
                                    <li class="m-ellipsis">QWEQWEffa2312</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref } from "vue"
const a = ref(0)
</script>
<style lang="less" scoped>
.pc {
    display: block;
}

.mobile {
    display: none;
}

.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.main {
    width: 100%;
    background-image: url('../assets/icon/list_bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat-y;
    padding-bottom: 60px;

    .item {
        width: 560px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(61, 67, 79, 0.33);
        border-radius: 13px 13px 13px 13px;

        margin-top: 60px;
        margin-right: 60px;
        display: inline-block;
        vertical-align: top;
        padding: 20px 30px;


        &:nth-of-type(2n) {
            margin-right: 0;
        }

        .type {
            width: 183px;
            line-height: 35px;
            border-radius: 17px;
            border: 2px solid #EA5520;
            padding: 0 15px;
            color: #343F49;
        }
    }

    .more {
        width: 90px;
        line-height: 26px;
        background: #E1E1E1;
        border-radius: 13px;
        text-align: center;
        font-size: 14px;
        color: #152F4A;
    }

    .cover {
        width: 225px;
        height: 218px;
        border-radius: 8px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
            object-fit: cover;
            transition: all 0.6s;
        }

        img:hover {
            transform: scale(1.4);
        }
    }

    .col152 {
        color: #152F4A;
    }

    .colea {
        color: #EA5520;
    }

    .item_ul {
        width: 100%;
        border-radius: 13px 13px 13px 13px;
        border: 2px solid #152F4A;
        margin-top: 20px;
        padding: 20px 15px;
        color: #152F4A;
        font-size: 12px;

        li {
            line-height: 22px;
            border-top: 1px solid #C0C6C8;
            width: 105px;
            display: inline-block;
            vertical-align: top;
            font-family: "Metropolis-Medium";

        }

        li:nth-of-type(2n) {
            padding-left: 8px;
            border-left: 1px solid #C0C6C8;
        }

        li:nth-of-type(-n+2) {
            border-top: none;
        }
    }
}

@media screen and (max-width: 1280px) {
    .w1270 {
        max-width: 1080px;
    }

    .main {
        .item {
            width: 530px;
            height: auto;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px 0px rgba(61, 67, 79, 0.33);
            border-radius: 13px 13px 13px 13px;

            margin-top: 15px;
            margin-right: 15px;
            display: inline-block;
            vertical-align: top;
            padding: 10px 15px;


            &:nth-of-type(2n) {
                margin-right: 0;
            }

            .type {
                width: 183px;
                line-height: 35px;
                border-radius: 17px;
                border: 2px solid #EA5520;
                padding: 0 15px;
                color: #343F49;
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .w1270 {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .pc {
        display: none;
    }

    .mobile {
        display: block;
    }

    .main {
        padding-bottom: 30px;
        .item {
            width: 100%;
            height: auto;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px 0px rgba(61, 67, 79, 0.33);
            border-radius: 13px 13px 13px 13px;

            margin-top: 15px;
            margin-right: auto;
            margin-left: auto;
            display: block;
            padding: 10px 15px;


            &:nth-of-type(2n) {
                margin-right: 0;
            }

            .type {
                width: 183px;
                line-height: 35px;
                border-radius: 17px;
                border: 2px solid #EA5520;
                padding: 0 15px;
                color: #343F49;
            }
        }

        .item_ul {
            width: 100%;
            border-radius: 13px 13px 13px 13px;
            border: 2px solid #152F4A;
            margin-top: 10px;
            padding: 20px 15px;
            color: #152F4A;
            font-size: 12px;

            li {
                line-height: 22px;
                border-top: 1px solid #C0C6C8;
                width: 50%;
                display: inline-block;
                vertical-align: top;
                font-family: "Metropolis-Medium";

            }

            li:nth-of-type(2n) {
                padding-left: 8px;
                border-left: 1px solid #C0C6C8;
            }

            li:nth-of-type(-n+2) {
                border-top: none;
            }
        }
        .cover {
        width: 225px;
        height: 218px;
        border-radius: 8px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
    }
    }

}
</style>
