<!--
 * @author: JJ
 * @since: 2024-11-11
 * ParnerSearch.vue
-->
<template>
    <div class="container" >
        <Header></Header>
      
        <section v-if="detail">
            <img :src="detail.image" class="blog_banner" alt="">
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr lh18 pb70">
                <div>
                  <div class="before_link"> <router-link to="/">Home /</router-link></div>
                  <div class="before_link"><router-link to="/BecomePartner">Network /</router-link></div>
                  <div class="now_link"><router-link to="/PartnerSearch">Partner Search</router-link></div>
                </div>
                <div class="pt70 tc fs40 fwb lh40">PARTNER TIERS</div>
                <div class="mt10 fs16 col89 lh16 tc">Please select country/region keywords to search for partners</div>
                <div class="pt20 w1150 ">


                  <el-select v-model="nation_id" @change="getCitys" placeholder="Please select  country" class="custom-select ">
                    <el-option v-for="item in nations" :key="item.id" :label="item.name"
                               :value="item.id" />
                  </el-select>
                  <el-select v-model="city_id" placeholder="Please Select Region" class="custom-select ">
                    <el-option v-for="item in citys" :key="item.id" :label="item.name"
                               :value="item.id" />
                  </el-select>
                    <input class="search_nav " v-model="keywords" placeholder="Keyword" />
                    <button class="search_btn" @click="searchs">SEARCH</button>
                </div>
              <template v-if="is_search==1">

                <ul class="pc"  >

                    <li class="result_item">
                        <p class="fs30 lh30 fwb col1">Country Dealer</p>
                       <div class="flex-box box size0  cp" v-for="(item, index) in country_dealer" :key="index">
                            <div class="img_nav">
                                <img :src="item.img" alt="">
                            </div>
                            <div class="flex-grow-1 pl40">
                                <div class="w500">
                                    <p class="m-ellipsis-l2 fs30 lh40">{{item.shop_name}}</p>
                                    <div class="flex mt30">
                                        <img src="../assets/icon/icon_phone.png" class="ico14" alt="">
                                        <div class="fs16 pl10">{{item.mobile}}</div>
                                    </div>
                                    <div class="flex mt15">
                                        <img src="../assets/icon/icon_email.png" class="ico14" alt="">
                                        <div class="fs16 pl10">{{item.email}}</div>
                                    </div>
                                    <div class="flex mt15">
                                        <img src="../assets/icon/icon_address.png" class="ico14" alt="">
                                        <div class="fs16 pl10">{{item.address}}</div>
                                    </div>
                                    <router-link class="mt45 col4 fs16 cp" :to="{ name: 'DealerDetails', query: { id: item.id } }" >MORE+</router-link>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="result_item">
                        <p class="fs30 lh30 fwb col1">Certified Shop</p>
                      <div class="flex-box box size1  cp" v-for="(item, index) in certified_shop" :key="index">
                        <div class="img_nav">
                          <img :src="item.img" alt="">
                        </div>
                        <div class="flex-grow-1 pl40">
                          <div class="w500">
                            <p class="m-ellipsis-l2 fs30 lh40">{{item.shop_name}}</p>
                            <div class="flex mt30">
                              <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.mobile}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.email}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.address}}</div>
                            </div>
                            <router-link class="mt45 col4 fs16 cp" :to="{ name: 'DealerDetails', query: { id: item.id } }" >MORE+</router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="result_item">
                        <p class="fs30 lh30 fwb col1">Reseller</p>
                      <div class="flex-box box size2  cp" v-for="(item, index) in reseller" :key="index">
                        <div class="img_nav">
                          <img :src="item.img" alt="">
                        </div>
                        <div class="flex-grow-1 pl40">
                          <div class="w500">
                            <p class="m-ellipsis-l2 fs30 lh40">{{item.shop_name}}</p>
                            <div class="flex mt30">
                              <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.mobile}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.email}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.address}}</div>
                            </div>
                            <router-link class="mt45 col4 fs16 cp" :to="{ name: 'DealerDetails', query: { id: item.id } }" >MORE+</router-link>
                          </div>
                        </div>
                      </div>
                    </li>

                </ul>
                <ul class="mobile" >
                    <li class="result_item"  >
                        <p class="fs30 lh30 fwb col1">Country Dealer</p>
                        <div class="box size0 cp" v-for="(item, index) in country_dealer" :key="index">
                            <div class="img_nav" >
                                <img :src="item.img" alt="">
                            </div>
                            <div class="mt30">
                                <div class="w500">
                                    <p class="m-ellipsis-l2 fs30 lh40">{{item.shop_name}}</p>
                                    <div class="flex mt30">
                                        <img src="../assets/icon/icon_phone.png" class="ico14" alt="">
                                        <div class="fs16 pl10">{{item.mobile}}</div>
                                    </div>
                                    <div class="flex mt15">
                                        <img src="../assets/icon/icon_email.png" class="ico14" alt="">
                                        <div class="fs16 pl10">{{item.email}}</div>
                                    </div>
                                    <div class="flex mt15">
                                        <img src="../assets/icon/icon_address.png" class="ico14" alt="">
                                        <div class="fs16 pl10">{{item.address}}</div>
                                    </div>
                                    <router-link class="mt45 col4 fs16 cp" :to="{ name: 'DealerDetails', query: { id: item.id } }" >MORE+</router-link>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="result_item">
                        <p class="fs30 lh30 fwb col1">Certified Shop</p>
                      <div class="box size1 cp" v-for="(item, index) in resList" :key="index">
                        <div class="img_nav" >
                          <img :src="item.img" alt="">
                        </div>
                        <div class="mt30">
                          <div class="w500">
                            <p class="m-ellipsis-l2 fs30 lh40">{{item.shop_name}}</p>
                            <div class="flex mt30">
                              <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.mobile}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.email}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.address}}</div>
                            </div>
                            <router-link class="mt45 col4 fs16 cp" :to="{ name: 'DealerDetails', query: { id: item.id } }" >MORE+</router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="result_item">
                        <p class="fs30 lh30 fwb col1">Reseller</p>
                      <div class="box size2 cp" v-for="(item, index) in reseller" :key="index">
                        <div class="img_nav" >
                          <img :src="item.img" alt="">
                        </div>
                        <div class="mt30">
                          <div class="w500">
                            <p class="m-ellipsis-l2 fs30 lh40">{{item.shop_name}}</p>
                            <div class="flex mt30">
                              <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.mobile}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.email}}</div>
                            </div>
                            <div class="flex mt15">
                              <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                              <div class="fs16 pl10">{{item.address}}</div>
                            </div>
                            <router-link class="mt45 col4 fs16 cp" :to="{ name: 'DealerDetails', query: { id: item.id } }" >MORE+</router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>

              </template>
              <ul class="pt35" >
                <li class="recomd_item col1" v-for="(item, index) in resList" :key="index">

                    <div class="img_nav">
                      <img :src="item.image" alt="">
                    </div>
                    <div class="pt25 plr30 pb30">
                      <p class="fwb fs24">{{item.name}}</p>
                      <div class="mt20 fs16 col5 lh20 m-ellipsis-l9">
                       {{item.desc}}
                      </div>
                    </div>

                </li>
              </ul>


            </div>
        </section>
      <section class="w1270 flex-box flex-center nothing" v-else>
        <h1></h1>
      </section>
        <Footer></Footer>

    </div>
</template>

<script setup>
import {onMounted, ref,onBeforeMount} from "vue"
import {apiShopList, apiCountryList, apiCityList, apiArticle} from "../request/api";
import {useHead} from "@unhead/vue";

const value = ref('')
const nations =ref([]);
const citys= ref([]);
const explains=ref('');
const username=ref('');
const phone=ref('');
const nation_id=ref('');
const city_id=ref('');
const detail=ref(null);

const reseller=ref([]);
const certified_shop=ref([]);
const country_dealer=ref([]);

let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据

let is_search=ref(0)


const searchs= async (e) =>{
  if(nation_id.value==0){
     alert('Please select a country.');
     return
  }
  if(city_id.value==0){
    alert('Please select a region.');
    return
  }
  is_search.value=1;

  getList();
}
onMounted(() => {
  getNations();
  getList()
});
const getList = async () => {
  try {

    const res = await apiShopList({
      nation_id:nation_id.value,
      city_id:city_id.value,
      keywords:keywords.value
    });
    reseller.value=res.data.reseller;
    certified_shop.value=res.data.certified_shop;
    country_dealer.value=res.data.country_dealer;
    resList.value = [];
    resList.value = res.data.categorys ;



  } catch (e) {

  }
}


const getNations= async ()=>{
  const res=await apiCountryList();
  nations.value=res.data;

}
const keywords='';
onBeforeMount(()=>{
  getInfos()
})

const getInfos = async () => {
  try {

    const res = await apiArticle({title:'Partner Search'}); // 调用接口
    console.log(res);

    detail.value=res.data;

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const getCitys =async (e)=>{

  const res1=await apiCityList({country_id:nation_id.value});
  city_id.value='';
  citys.value=res1.data;
}


</script>
<style lang="less" scoped>
.container{
    background: linear-gradient( 180deg, #FFFFFF 0%, #ECF0F8 100%);
}
.pc {
    height: auto;
    overflow: visible;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.search_nav {
    width: 320px;
    height: 60px;
    border-radius: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #333333;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    font-size: 16px;
    color: #101010;

}

.search_btn {
    color: #FFFFFF;
    width: 120px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #FB471F;
    border-radius: 18px;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    cursor: pointer;
}

.recomd_item {
    display: inline-block;
    vertical-align: top;
    margin-top: 35px;
    margin-right: 35px;
    width: 400px;
    height: 547px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    cursor: pointer;

    &:nth-of-type(3n) {
        margin-right: 0;
    }

    .img_nav {
        width: 100%;
        height: 271px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            display: block;
            transition: all 0.6s;
            object-fit: cover;
        }

        img:hover {
            transform: scale(1.4);
        }
    }
}

.result_item {
    margin-top: 60px;

    .box {
        margin-top: 40px;
        width: 100%;
        border-radius: 10px;

        .img_nav {
            width: 500px;
            height: 338px;
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transition: all 0.6s;
                border-radius: 10px;
            }

            img:hover {
                transform: scale(1.4);
            }
        }

        a {
            display: block;
        }
    }

    .box.size0 {
        background: #27334A;
        box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
        color: #fff;
        border-radius: 10px;
    }

    .box.size1 {
        background: #C1C5C8;
        box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
        color: #101010;
        border-radius: 10px;
    }

    .box.size2 {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
        color: #101010;
        border-radius: 10px;
    }
}
.nothing{
    height: 300px;
    h1{
        font-size: 40px;
        color: #101010;
    }
}
@media screen and (max-width: 1270px) {
    .recomd_item {
        &:nth-of-type(2n) {
        margin-right: 0;
    }
        &:nth-of-type(3n) {
        margin-right: 35px;
    }
}
}



@media screen and (max-width: 980px) {
    .recomd_item {
        display: block;
        margin-top: 35px;
        margin-right: auto;
        margin-right: auto;
        width: 100%;
        height: 547px;
        overflow: hidden;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        cursor: pointer;

        &:nth-of-type(2n) {
        margin-right: auto;
    }
        &:nth-of-type(3n) {
        margin-right: auto;
    }

        .img_nav {
            width: 100%;
            height: 271px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                display: block;
                transition: all 0.6s;
                object-fit: cover;
            }

            img:hover {
                transform: scale(1.4);
            }
        }
    }

    .mobile {
        height: auto;
        overflow: visible;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .result_item {
        margin-top: 60px;

        .box {
            margin-top: 40px;
            width: 100%;
            border-radius: 10px;
            padding-bottom: 20px;

            .img_nav {
                width: 100%;
                height: calc(100% / 500 * 338);
                overflow: hidden;
                border-radius: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    transition: all 0.6s;
                    border-radius: 10px;
                }

                img:hover {
                    transform: scale(1.4);
                }
            }
        }

        .box.size0 {
            background: #27334A;
            box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
            color: #fff;
        }

        .box.size1 {
            background: #C1C5C8;
            box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
            color: #101010;
        }

        .box.size2 {
            background: #FFFFFF;
            box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.05);
            color: #101010;
        }
    }
}
</style>


<style scoped>
.custom-select {
    width: 320px;
    line-height: 60px;
    height: 60px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;

}

::v-deep.custom-select.el-select .el-select__wrapper {
    font-size: 16px;
    line-height: 60px;
    min-height: 60px;
    box-shadow: none;
    padding: 0 20px;
    border: 1px solid #333333;
    border-radius: 18px;
    color: #333;
}

.custom-select .el-input__inner {
    height: 60px;
    /* 自定义输入框高度 */
    line-height: 60px;
    /* 确保文本在输入框中垂直居中 */
}
</style>