<!--
 * @author: JJ
 * @since: 2024-11-11
 * DealerDetails.vue
-->
<template>
    <div class="container" >
        <Header></Header>
        <section v-if="detail">
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr lh18 pb70">
                <div>
                  <div class="before_link"> <router-link to="/">Home /</router-link></div>
                  <div class="before_link"><router-link to="/PartnerSearch">Network /</router-link></div>
                  <div class="before_link"><router-link to="/PartnerSearch">Partner Search /</router-link></div>
                    <div class="now_link" ><router-link :to="{ name: 'DealerDetails', query: { id: detail.id } }" >Dealer Details</router-link></div>
                </div>
                <div class="pt65 fs40 fwb lh40">Dealer Information</div>
                <section class="pc">
                    <div class="pt60 flex-box top_info">
                        <div class="img_nav">
                            <img :src="detail.shop_image" alt="">
                        </div>
                        <div class="flex-grow-1 pl50 col1 fs16">
                            <p class="fs30 lh40">{{detail.shop_name}}</p>
                            <div class="mt15 flex-box">
                                <img v-for="(item, index) in detail.stars" :key="index" src="../assets/icon/icon_star_red.png" class="star" alt="">

                            </div>
                            <div class="mt40 flex">
                                <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.mobile}}</div>
                            </div>
                            <div class="mt10 flex">
                                <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.email}}</div>
                            </div>
                            <div class="mt10 flex bb pb40">
                                <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.address}}</div>
                            </div>
                            <div class="mt30 lh26">{{detail.desc}}</div>
                        </div>
                    </div>
                    <div class="pt100 flex-box fs16 col1 lh26">
                        <div class="flex-grow-1 pr70" v-html="detail.content"></div>
                        <img :src="detail.image" class="img537" alt="">
                    </div>
                    <div class="fwb fs40 col1 pt70">Picture</div>
                    <ul class="w1270" id="pic1">
                        <li class="picture_li fwb fs20 lh24 col1" v-for="(item, index) in  detail.articles" :key="index">
                          <router-link :to="{ name: 'TrainingDetail', query: { id: item.id,shop_id:detail.id } }">
                                <div class="img_nav">
                                <img :src="item.image" alt="">
                            </div>
                            <div class="mt20 m-ellipsis-l2">{{item.title}}</div>
                            </router-link>
                        </li>
                    </ul>
                </section>
                <section class="mobile">
                    <div class="pt60 top_info">
                        <div class="img_nav">
                            <img :src="detail.shop_image" alt="">
                        </div>
                        <div class="mt30 col1 fs16">
                            <p class="fs30 lh40">{{detail.shop_name}}</p>
                            <div class="mt15 flex-box">
                              <img v-for="(item, index) in detail.stars" :key="index" src="../assets/icon/icon_star_red.png" class="star" alt="">

                            </div>
                            <div class="mt40 flex">
                                <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.mobile}}</div>
                            </div>
                            <div class="mt10 flex">
                                <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.email}}</div>
                            </div>
                            <div class="mt10 flex bb pb40">
                                <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.address}}</div>
                            </div>
                            <div class="mt30 lh26">{{detail.desc}}</div>
                        </div>
                    </div>
                    <div class="pt100  fs16 col1 lh26">
                        <img :src="detail.image" class="img537" alt="">
                        <div class="mt30" v-html="detail.content"> </div>
                    </div>
                    <div class="fwb fs40 col1 pt70">Picture</div>
                    <ul class="w1270" id="pic">
                        <li class="picture_li fwb fs20 lh24" v-for="(item, index) in detail.articles" :key="index">
                            <router-link :to="{ name: 'TrainingDetail', query: { id: item.id,shop_id:detail.id } }">
                                <div class="img_nav">
                                <img :src="item.image" alt="">
                            </div>
                            <div class="mt20 m-ellipsis-l2">{{item.title}}</div>
                            </router-link>
                            
                        </li>
                    </ul>
                </section>
            </div>
        </section>
        <Footer></Footer>

    </div>
</template>

<script setup>
import {onMounted, ref,onBeforeMount} from "vue"
import {apiArticle, apiShopDetail} from "../request/api";
import {useHead} from "@unhead/vue";
import { useRouter ,onBeforeRouteUpdate} from 'vue-router';
const router = useRouter();
const a = ref(0);
const detail =ref(null);
const params=router.currentRoute.value.query;

const id=ref(0);
if(params.id){
  id.value=params.id;
}
onBeforeRouteUpdate((to) => {
  if(to.query.id){
    id.value=to.query.id;
  }
  getInfo()
});
onBeforeMount(() => {
  getInfo()
})
const getInfo = async () => {
  try {

    const res = await apiShopDetail({ id:id.value }); // 调用接口

    detail.value = res.data;

    useHead({
      title: res.data.shop_name+'_JOYVIE',
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
 .pc {
        height: auto;
        overflow: auto;
    }

    .mobile {
        height: 0;
        overflow: hidden;
    }
.top_info {
    .img_nav {
        width: 725px;
        height: 450px;
        overflow: hidden;
        border-radius: 10px;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: all 0.6s;
        }

        img:hover {
            transform: scale(1.4);
        }
    }

    .star {
        width: 16px;
        height: auto;
        margin-right: 5px;
    }
}

.bb {
    border-bottom: 1px solid #EEEEEE;
}

.img537 {
    width: 537px;
    height: auto;
}

.picture_li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 400px;
    margin-right: 35px;
color: #101010;
  &:hover{
    color: #FB471F;
  }
    .img_nav {
        width: 400px;
        height: 243px;
        overflow: hidden;
        border-radius: 7px;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 7px;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.picture_li:nth-of-type(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1280px) {
    .top_info {
        .img_nav {
            width: calc(725px * 0.7);
            height: calc(450px * 0.7);
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                transition: all 0.6s;
            }

            img:hover {
                transform: scale(1.4);
            }
        }

       
        .star {
            width: 16px;
            height: auto;
            margin-right: 5px;
        }
    }
    .img537 {
            width: calc(537px * 0.7);
            height: auto;
        }

    .picture_li {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        width: 400px;
        margin-right: 35px;

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 35px;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    #pic.w1270 {
        max-width: 835px;
    }
   #pic1.w1270 {
    max-width: 835px;
  }
}

@media screen and (max-width: 980px) {
    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .mt60 {
        margin-top: 30px;
    }

    .w1000 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .w1027 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .mt80 {
        margin-top: 40px;
    }

    .pt60 {
        padding-top: 30px;
    }

    .pt70 {
        padding-top: 45px;
    }

    .pt65 {
        padding-top: 35px;
    }

    .picture_li {
        display: block;
        margin-top: 40px;
        width: 100%;
        margin-right: 0;

        .img_nav {
            width: 100%;
            height: 243px;
            overflow: hidden;
            border-radius: 7px;
        }

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 0;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }
    .top_info {
        .img_nav {
            width: 100%;
            height: auto;
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                transition: all 0.6s;
            }

            img:hover {
                transform: scale(1.4);
            }
        }

       
        .star {
            width: 16px;
            height: auto;
            margin-right: 5px;
        }
    }
    .img537 {
            width: 100%;
            height: auto;
        }
}
</style>
