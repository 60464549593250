<!--
 * @author: JJ
 * @since: 2024-11-11
 * Training.vue
-->
<template>
  <div class="container" v-if="detail">
    <Header></Header>
    <section>
      <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr  lh18">
        <div>
          <div class="before_link"> <router-link to="/">Home /</router-link></div>
          <div class="before_link">Network /</div>
          <div class="before_link">  <router-link to="/PartnerSearch">Partner Search {{shop_id!=0?'/':''}}</router-link></div>
          <div class="before_link" v-if="shop_id!=0"><router-link :to="{ name: 'DealerDetails', query: { id: shop_id }} ">Dealer Details</router-link></div>
          <div class="now_link"><router-link :to="{ name: 'TrainingDetail', query: { id: detail.id,shop_id:shop_id } }" >{{detail.title}} </router-link></div>
        </div>
        <div class="pt65 fs40 fwb lh40">{{detail.title}}</div>
        <section class="pc">
          <div class="flex-box col1 pb80 pt60">
            <img :src="detail.images" class="avatar" alt="">
            <div class="flex-grow-1 pl35 fs30 lh30">
              <div>{{detail.name}}<span class="pl10 col89 ">{{detail.sub_name}}</span></div>
              <div class="mt30 fs16 lh26" v-html="detail.content">
              </div>
            </div>
          </div>
        </section>
        <section class="mobile">
          <div class="col1 pb80 pt60">
            <img :src="detail.images" class="avatar" alt="">
            <div class="mt30 fs30 lh30">
              <div>{{detail.title}}<span class="pl10 col89 ">{{detail.sub_name}}</span></div>
              <div class="mt30 fs16 lh26" v-html="detail.content">

              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="case_bg ptb80">
        <div class="w1270">
          <div class="fwb fs40 col1">Related Cases</div>
          <ul class="w1270" id="pic" >
            <li class="picture_li fwb fs20 lh24" v-for="(item, index) in detail.cases" :key="index">
              <router-link :to="{name: 'ExampleInfo', query: {id:item.id,type:3,shop_id:shop_id,t_id:detail.id}}">
                <div class="img_nav">
                  <img :src="item.image" alt="">
                </div>
                <div class="mt20 m-ellipsis-l2">{{item.title}}</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue"
import {apiGetTrainDetail} from "../request/api";
import {useHead} from "@unhead/vue";
import { useRouter ,onBeforeRouteUpdate} from 'vue-router';
const router = useRouter();
const a = ref(0)
const detail=ref(null);
const params=router.currentRoute.value.query;
const shop_id=ref(0);
const id=ref(0);

if(params.id){
  id.value=params.id;
}
if(params.shop_id){
  shop_id.value=params.shop_id;
}
onBeforeMount(()=>{
  getInfos()
})
const getInfos = async () => {
  try {

    const res = await apiGetTrainDetail({id:id.value}); // 调用接口
    if(res.code!=1){
      if(res.code==401){
        if (confirm("please log in first")) {
          // 用户点击了确定
          router.push({ path: '/Login' });
        } else {
          // 用户点击了取消
          router.push({ path: '/' });
        }
      }
      return
    }
    detail.value=res.data;

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.avatar {
  width: 620px;
  height: 450px;
  object-fit: cover;
  display: block;
}

.pc {
  height: auto;
  overflow: visible;
}

.mobile {
  height: 0;
  overflow: hidden;
}

.case_bg {
  background: #F3F7FF;
}

.picture_li {
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
  width: 400px;
  margin-right: 35px;
color: #101010;
  .img_nav {
    width: 400px;
    height: 243px;
    overflow: hidden;
    border-radius: 7px;
  }
&:hover{color: #FB471F}
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 7px;
    transition: all 0.6s;
  }

  img:hover {
    transform: scale(1.4);
  }
}

.picture_li:nth-of-type(3n) {
  margin-right: 0;
}

@media screen and (max-width: 1280px) {
  .avatar {
    width: calc(620px * 0.7);
    height: calc(450px * 0.7);
    object-fit: cover;
    display: block;
  }

  .picture_li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 400px;
    margin-right: 35px;

    img {
      width: 100%;
      height: 243px;
      display: block;
      object-fit: cover;
    }
  }

  .picture_li:nth-of-type(3n) {
    margin-right: 35px;
  }

  .picture_li:nth-of-type(2n) {
    margin-right: 0px;
  }

  #pic.w1270 {
    max-width: 835px;
  }
}


@media screen and (max-width: 980px) {
  .mobile {
    height: auto;
    overflow: auto;
  }

  .pc {
    height: 0;
    overflow: hidden;
  }

  .w1270 {
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt60 {
    margin-top: 30px;
  }

  .w1000 {
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .w1027 {
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .mt80 {
    margin-top: 40px;
  }

  .pt60 {
    padding-top: 30px;
  }

  .pt70 {
    padding-top: 45px;
  }

  .pt65 {
    padding-top: 35px;
  }

  .picture_li {
    display: block;
    margin-top: 40px;
    width: 100%;
    margin-right: 0;

    .img_nav {
      width: 100%;
      height: 243px;
      overflow: hidden;
      border-radius: 7px;
    }

    img {
      width: 100%;
      height: 243px;
      display: block;
      object-fit: cover;
    }
  }

  .picture_li:nth-of-type(3n) {
    margin-right: 0;
  }

  .picture_li:nth-of-type(2n) {
    margin-right: 0px;
  }


}
</style>
