import {createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/style.css";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Header from "../src/components/header.vue";
import Footer from "../src/components/footer.vue";
import Fixed from "../src/components/fixed.vue";
import needHelp from "./components/needHelp.vue";
import PaginationW from './components/pagination.vue';
import * as api from "./request/api.js"; // 导入api接口
import { createHead } from '@unhead/vue';
import AnimatedNumber from 'animated-number-vue3'
import "animate.css";
import './assets/css/myanimate.css'

import VueCookies from 'vue3-cookies';


const app = createApp(App);
const head = createHead();
app.use(head);
app.use(AnimatedNumber)


app.config.productionTip = false; // 关闭生产提示
app.component("Header", Header);
app.component("Footer", Footer);
app.component("Fixed", Fixed);
app.component("needHelp", needHelp);
app.component("paginationW", PaginationW);
app.config.globalProperties.$api = api;
// 设置 cookie 的默认配置（可选）

app.use(ElementPlus)
app.use(VueCookies);
app.use(store);
app.use(router);
app.mount("#app");
