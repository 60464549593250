import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import HomeView from "../views/Home.vue";
import loading from '../views/loading.vue';
import Blog from '../views/Blog.vue';
import Blogs from '../views/Blogs.vue';
import BlogInfo from '../views/BlogInfo.vue'
import AboutUs from '../views/AboutUs.vue';
import CoreValues from '../views/CoreValues.vue';
import PPF from '../views/PPF.vue';
import ProductInfo from '../views/ProductInfo.vue';
import ExampleInfo from '../views/ExampleInfo.vue';
import Software from '../views/Software.vue';
import Plotter from '../views/Plotter.vue';
import PlotterInfo from '../views/PlotterInfo.vue';
import Tools from '../views/Tools.vue';
import Tool from '../views/Tool.vue';
import ToolsInfo from '../views/ToolsInfo.vue';
import WarrantyCheck from '../views/WarrantyCheck.vue';
import FAQ from '../views/FAQ.vue';
import DownloadCenter from '../views/DownloadCenter.vue';
import ParnerSearch from '../views/ParnerSearch.vue';
import DealerDetails from '../views/DealerDetails.vue';
import Training from '../views/Training.vue';
import BecomePartner from '../views/BecomePartner.vue';
import Collection from '../views/Collection.vue';
import ContactUs from '../views/ContactUs.vue';
import DealerPortal from '../views/DealerPortal.vue';
import EditInformation from '../views/EditInformation.vue';
import Login from '../views/Login.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import ChangePassword from '../views/ChangePassword.vue';
import TrainingDetail from '../views/TrainingDetail.vue';
import ExampleList from '../views/ExampleList.vue';
import ResetPwd from '../views/ResetPwd.vue';
import BrandingElementsInfo from "../views/BrandingElementsInfo.vue";
import WarrantySearch from '../views/WarrantySearch.vue';
import WarrantyInfo from '../views/WarrantyInfo.vue';
import Warrantylist from '../views/Warrantylist.vue';
import NewPPF from '../views/NewPPF.vue';
const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/blog',
		name: 'blog',
		component: Blog
	},
	{
		path: '/blogs',
		name: 'blogs',
		component: Blogs
	},
	{
		path: '/blogInfo',
		name: 'blogInfo',
		component: BlogInfo
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: AboutUs
	},
	{
		path: '/coreValues',
		name: 'coreValues',
		component: CoreValues
	},
	{
		path: '/PPF',
		name: 'PPF',
		component: PPF
	},
	{
		path: '/ProductInfo',
		name: 'ProductInfo',
		component: ProductInfo
	},
	{
		path: '/ExampleInfo',
		name: 'ExampleInfo',
		component: ExampleInfo
	},
	{
		path: '/Software',
		name: 'Software',
		component: Software
	},
	{
		path: '/Plotter',
		name: 'Plotter',
		component: Plotter
	},
	{
		path: '/PlotterInfo',
		name: 'PlotterInfo',
		component: PlotterInfo
	},
	{
		path: '/Tools',
		name: 'Tools',
		component: Tools
	},
	{
		path: '/Tool',
		name: 'Tool',
		component: Tool
	},
	{
		path: '/ToolsInfo',
		name: 'ToolsInfo',
		component: ToolsInfo
	},
	{
		path: '/WarrantyCheck',
		name: 'WarrantyCheck',
		component: WarrantyCheck
	},
	{
		path: '/TrainingDetail',
		name: 'TrainingDetail',
		component: TrainingDetail
	},
	{
		path: '/FAQ',
		name: 'FAQ',
		component: FAQ
	},
	{
		path: '/DownloadCenter',
		name: 'DownloadCenter',
		component: DownloadCenter
	},
	{
		path: '/PartnerSearch',
		name: 'PartnerSearch',
		component: ParnerSearch
	},
	{
		path: '/DealerDetails',
		name: 'DealerDetails',
		component: DealerDetails
	},
	{
		path: '/Training',
		name: 'Training',
		component: Training
	},
	{
		path: '/BecomePartner',
		name: 'BecomePartner',
		component: BecomePartner
	},
	{
		path: '/Collection',
		name: 'Collection',
		component: Collection
	},
	{
		path: '/ContactUs',
		name: 'ContactUs',
		component: ContactUs
	},
	{
		path: '/DealerPortal',
		name: 'DealerPortal',
		component: DealerPortal
	},
	{
		path: '/EditInformation',
		name: 'EditInformation',
		component: EditInformation
	},
	{
		path: '/Login',
		name: 'Login',
		component: Login
	},
	{
		path: '/ForgetPassword',
		name: 'ForgetPassword',
		component: ForgetPassword
	},
	{
		path: '/ChangePassword',
		name: 'ChangePassword',
		component: ChangePassword
	},
	{
		path: '/ExampleList',
		name: 'ExampleList',
		component: ExampleList
	},
	{
		path: '/ResetPwd/:code',
		name: 'ResetPwd',
		component: ResetPwd,
	}, {
		path: '/BrandingElementsInfo',
		name: 'BrandingElementsInfo',
		component: BrandingElementsInfo,
	}

	, {
		path: '/WarrantySearch',
		name: 'WarrantySearch',
		component: WarrantySearch,
	}
	, {
		path: '/WarrantyInfo',
		name: 'WarrantyInfo',
		component: WarrantyInfo,
	}
	, {
		path: '/Warrantylist',
		name: 'Warrantylist',
		component: Warrantylist,
	}
	, {
		path: '/NewPPF',
		name: 'NewPPF',
		component: NewPPF,
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})
const routeHistory = {
	previousRoute: null
};

// 使用afterEach导航守卫来更新上一个页面的路由信息

router.beforeEach((to, from, next) => {

	// 在当前路由改变，但是该组件被复用时调用

	// 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，

	// 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。

	// 可以访问组件实例 `this`

	//console.log('beforeRouteUpdate被调用：在当前路由改变，但是该组件被复用时调用')

	next()

})

router.afterEach((to, from, next) => {
	if (from) {
		cookies.set('pre_path', from.name);
	}

	window, scrollTo(0, 0)
})
export default router