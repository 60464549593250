<!--
 * @author: JJ
 * @since: 2024-11-25
 * ExampleList.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section>
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr pb70">
                <div class=" lh18">
                  <div class="before_link"> <router-link to="/">Home /</router-link></div>
                    <div class="now_link"><router-link to="/ExampleList">Case</router-link></div>
                </div>
                <div class="pt65 fwb fs40 col1 lh40 pb30">Cases</div>
                <ul>
                    <li class="case_item" v-for="(item, index) in resList" :key="index">
                        <router-link :to="{ name: 'ExampleInfo', query: { id: item.id} }">
                            <div class="img_nav">
                                <img :src="item.image" alt="">
                            </div>
                            <p class="m-ellipsis-l2 fs20">{{ item.title }}</p>
                        </router-link>
                    </li>
                </ul>

              <pagination-w v-if="total > pageSize" :sumCount="total" :perCount="pageSize"
                            @pageTurn="getList" ref="pagination"></pagination-w>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from "vue"
import {apiArticle, apiGetCaseList, apiNewsList} from "../request/api";
import { useHead } from "@unhead/vue";
let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据
const a = ref(0)
useHead({
  title:'Cases_JOYVIE',
  meta: [
    {
      name: "description",
      content: 'Cases_JOYVIE',
    },
    {
      name: "keywords",
      content: 'Cases_JOYVIE',
    },
  ],
});
onBeforeMount(() => {
  getList()
})
const getList = async (e) => {
  try {

    const res = await apiGetCaseList({ page: e ? e + 1 : 1 });
    resList.value = [];
    resList.value = res.data.data;
    pageSize.value = res.data.per_page;
    total.value = res.data.total;
    pageNum.value = res.data.last_page;
    if(e){
      window.scrollTo(0, 0)
    }

  } catch (e) {

  }
}
</script>
<style lang="less" scoped>
.case_item {
    margin-top: 40px;
    margin-right: 35px;
    display: inline-block;
    width: 400px;
    vertical-align: top;
    cursor: pointer;

    .img_nav {
        width: 100%;
        height: 243px;
        border-radius: 7px;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.6s;
        object-fit: cover;
        border-radius: 7px;
    }

    img:hover {
        transform: scale(1.4);
    }

    p {
        margin-top: 20px;
        font-family: Metropolis-Medium;
        color: #101010;
        line-height: 24px;
    }

    &:hover p {
        color: #FB471F;
    }
}
.case_item:nth-of-type(3n) {
    margin-right: 0;
}
@media screen and (max-width: 1280px) {
    .case_item {
        width: calc(400px * 0.7);
        .img_nav {
            height: calc(243px * 0.7);
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 7px;
        }
    }
}
@media screen and (max-width: 980px) {
    .case_item {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .case_item:nth-of-type(3n) {
        margin-right: auto;
    }
}
</style>
