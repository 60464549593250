<!--
 * @author: JJ
 * @since: 2024-11-01
 * header.vue
-->
<template>
  <header>
    <div class="header_nav">
      <div class="w1270 flex-box flex-between pc">
        <img src="../assets/icon/head_logo.png" alt="">
        <ul class="tc pr z2">

          <li class="pr z2" v-for="(item, index) in tab" :key="index"
            :class="(nindex == index || findex == index) ? 'active' : ''" @mouseenter="chooseNav(index, 0)"
            @mouseleave="chooseNav(index, 1)">

            <div class="pr z2" v-if="item.children.length > 0">{{ item.name }}</div>
            <router-link :to="item.url" v-if="item.children.length == 0 && item.url != '/DealerPortal'">{{ item.name
              }}</router-link>
            <div class="pr z2" @click="gologin" v-if="item.children.length == 0 && item.url == '/DealerPortal' && !shop_info">{{
              item.name }}</div>
            <router-link :to="item.url" v-if="item.children.length == 0 && item.url == '/DealerPortal' && shop_info">{{
              item.name }}</router-link>
            <div class="popup"  :class="findex == index && showpop && midtab.length > 0 ? 'show' : 'leave'">
              <router-link class="li " :class="(nindex1 == index || findex1 == index) ? 'active' : ''" :to="item1.url"
                v-for="(item1, index1) in midtab" :key="index1">{{ item1.name }}</router-link>

            </div>
          </li>

        </ul>
        <div></div>
        <router-link class="fs14 col1" v-if="!shop_info" to="/Login">Login</router-link>
      </div>
      <div class="w1270 flex-box flex-between mobile">
        <img src="../assets/icon/head_logo.png" alt="">
        <div class="more flex-box flex-center" @click="showmobpop = true">
          <img src="../assets/icon/icon_more.png" alt="">
        </div>
      </div>
      <div class="popup-mask" :class="showmobpop ? 'popup-mask_visible' : ''" @click="showmobpop = false"></div>
      <div class="popup-wrap" :class="showmobpop ? 'popup-wrap_visible' : ''">
        <div class="popup1" :class="showmobpop ? 'show' : ''">
          <img src="../assets/icon/icon_close_black.png" class="close" @click="showmobpop = false" alt="">
          <div class="li" v-for="(item, index) in tab1"
            :key="index">
            <!-- <div class="flex-box">

                          <router-link :to="item.url" class="flex-grow-1 cp" v-if="item.url!='/DealerPortal'">{{ item.name }}</router-link>
                          <router-link :to="item.url" class="flex-grow-1 cp" v-if="item.url=='/DealerPortal'&&shop_info">{{ item.name }}</router-link>
                          <div @click="gologin" v-if="item.url=='/DealerPortal'&&!shop_info">{{item.name}}</div>
                        </div> -->
            <router-link :to="item.url" v-if="item.url != '/DealerPortal'&&item.children.length==0" class="flex-box">
              <P :class="(findex == index || nindex == index) ? 'active' : ''">{{ item.name }}</P>

            </router-link>
            <div v-if="item.url != '/DealerPortal'&&item.children.length>0" class="flex-box"  @click="choosem(index, 0)">
              <P class="flex-grow-1" :class="(findex == index || nindex == index) ? 'active' : ''">{{ item.name }}</P>
              <img src="../assets/icon/arrow_up.png"  v-if="(nindex == index||findex==index)" class="arrow" alt="">
              <img src="../assets/icon/arrow_down.png" v-else class="arrow" alt="">
            </div>
            <router-link :to="item.url" v-if="item.url == '/DealerPortal'&&shop_info" class="flex-box">
              <P  :class="(findex == index || nindex == index) ? 'active' : ''">{{ item.name }}</P>
            </router-link>
            <div @click="gologin(index)" :class="(findex == index || nindex == index) ? 'active' : ''" v-if="item.url == '/DealerPortal' && !shop_info">{{ item.name }}</div>

            <router-link class="mid_item show" :to="item1.url" v-if="findex == index&& midtab.length > 0"   v-for="(item1, index1) in midtab" :key="index1">
              <div class="item">{{ item1.name }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { onBeforeMount, ref, getCurrentInstance } from "vue";

import { useRouter } from "vue-router";
import { apigetinfos } from "../request/api";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const tab = ref([{ name: 'Home', children: [], url: '/' },
{ name: 'About Joyvie', children: [{ 'name': 'Blog', url: '/blog' }, { name: 'About Us', url: '/aboutUs' }, { name: 'Core Values', url: '/coreValues' }] },
{ name: 'Products', children: [{ name: 'PPF & Window Film', url: '/PPF' }, { name: 'Precut Software', url: '/Software' }, { name: 'Plotter', url: '/Plotter' }, { name: 'Tools', url: '/Tools' }] },
{ name: 'Technical Center', children: [{ name: 'Warranty Check', url: '/WarrantyCheck' }, { name: 'FAQ', url: '/FAQ' }, { name: 'Download Center', url: '/DownloadCenter' }, { name: 'Training', url: '/Training' }] },
{ name: 'Network', children: [{ name: 'PartnerSearch', url: '/PartnerSearch' }, { name: 'Become Partner', url: '/BecomePartner' }, { name: 'Collection', url: '/Collection' }] },
{ name: 'Contact Us', children: [], url: '/ContactUs' },
{ name: 'Dealer Portal', children: [], url: '/DealerPortal' },
])

const tab1 = ref([{ name: 'Home', url: '/' ,children:[]},
{ name: 'About Joyvie', url: '/blog', children: [{ 'name': 'Blog', url: '/blog' }, { name: 'About Us', url: '/aboutUs' }, { name: 'Core Values', url: '/coreValues' }] },
{ name: 'Products', url: '/PPF', children: [{ name: 'PPF & Window Film', url: '/PPF' }, { name: 'Precut Software', url: '/Software' }, { name: 'Plotter', url: '/Plotter' }, { name: 'Tools', url: '/Tools' }] },
{ name: 'Technical Center', url: '/WarrantyCheck', children: [{ name: 'Warranty Check', url: '/WarrantyCheck' }, { name: 'FAQ', url: '/FAQ' }, { name: 'Download Center', url: '/DownloadCenter' }, { name: 'Training', url: '/Training' }] },
{ name: 'Network', url: '/PartnerSearch', children: [{ name: 'Partner Search', url: '/PartnerSearch' }, { name: 'Become Partner', url: '/BecomePartner' }, { name: 'Collection', url: '/Collection' }] },
{ name: 'Contact Us', url: '/ContactUs', children: [] },
{ name: 'Dealer Portal', url: '/DealerPortal', children: [] },
])

let midtab = ref([])
let nindex = ref(-1);
let nindex1 = ref(-1);

let findex = ref(-1);
let findex1 = ref(-1);
let showpop = ref(false)
let showmobpop = ref(false);

const shop_info = ref(null);



const router = useRouter();
const currentRoute = router.currentRoute.value;
const params = router.currentRoute.value.query;

const type = ref('');
if (params.type) {
  type.value = params.type;
}
const m = ref('');
if (currentRoute) {
  m.value = currentRoute.name.toLocaleLowerCase();

  if (m.value == 'ppf' ||m.value=='windowfilm'|| m.value == 'plotter' || m.value == 'plotterinfo' || m.value == 'productinfo' || m.value == 'software' || m.value == 'tools' || m.value == 'toolsinfo') {
    nindex.value = 2;
    if (m.value == 'ppf' ) {
      nindex1.value = 0;
    }
    if(m.value == 'productinfo'){
      nindex1.value = 0;
      if(type.value==2){
        nindex1.value = 4;
      }
    }
    if (m.value == 'software') {
      nindex1.value = 1;
    }
    if (m.value == 'plotter' || m.value == 'plotterinfo') {
      nindex1.value = 2;
    }
    if (m.value == 'tools' || m.value == 'toolsinfo') {
      nindex1.value = 3;
    }
    if(m.value=='windowfilm'){
      nindex1.value = 4;
    }
  }
  if (m.value == 'blog' || m.value == 'blogs' || m.value == 'bloginfo' || m.value == 'aboutus' || m.value == 'corevalues') {
    nindex.value = 1;
    if (m.value == 'blog' || m.value == 'blogs' || m.value == 'bloginfo') {
      nindex1.value = 0;
    }
    if (m.value == 'aboutUs') {
      nindex1.value = 1;
    }
    if (m.value == 'corevalues') {
      nindex1.value = 2;
    }
  }


  if (m.value == 'warrantycheck' || m.value == 'faq' || m.value == 'downloadcenter' || m.value == 'training') {
    nindex.value = 3;

    if (m.value == 'warrantycheck') {

      nindex1.value = 0;
    }
    if (m.value == 'faq') {

      nindex1.value = 1;
    }
    if (m.value == 'downloadcenter') {

      nindex1.value = 2;
    }


  }
  if (m.value == 'exampleinfo') {

  }
  if (m.value == 'partnersearch' || m.value == 'becomepartner' || m.value == 'collection') {
    nindex.value = 4;
  }
  if (m.value == 'contactus') {
    nindex.value = 5;
  }
  if (m.value == 'dealerportal' || m.value == 'editInformation' || m.value == 'trainingdetail') {

    nindex.value = 6;
  }
}


const choosem=(index)=>{
  if(findex.value==index){
    findex.value = -1;
  }else{
    findex.value = index;
  }

  midtab.value = tab1.value[index].children;
  showpop.value = !showpop.value;

}
const chooseNav = (index, is_click) => {
  findex.value = index;
  midtab.value = tab.value[index].children;
  showpop.value = true;
  if (is_click == 1) {
    showpop.value = false;
    findex.value = -1;
  }


}
const chooseNav1 = (index, is_click) => {
  findex1.value = index;

}
const { proxy } = getCurrentInstance()
onBeforeMount(() => {

  getshopinfos()
})
const getshopinfos = () => {
  if (cookies.get('joyive_shop_info')) {
    shop_info.value = cookies.get('joyive_shop_info');
  }
  console.log(cookies.get('joyive_shop_token'))
  const res = apigetinfos({});

  if (res.data) {
    shop_info.value = res.data.login ? res.data.info : null;
    if (shop_info.value) {
      cookies.set('joyive_shop_token', shop_info.value.token);
      cookies.set('joyive_shop_info', JSON.stringify(shop_info.value));
    }
  }

}

const gologin = (index) => {
  findex.value = index;
  if (confirm("please log in first")) {
    // 用户点击了确定
    router.push({ path: '/Login' });
  }
}


</script>
<style lang="less" scoped>
header {
  height: 50px;
}

.header_nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(97, 121, 152, 0.1);

  img {
    width: 102px;
    height: auto;
  }

  li {
    padding: 0 12px;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    line-height: 26px;
    border: 2px solid #FFFFFF;
    position: relative;

    &:hover {
      border: 2px solid #FB471F;
      color: #FB471F;
      font-weight: 500;
    }

    &.active {
      border: 2px solid #FB471F;
      color: #FB471F;
      font-weight: 500;
    }
  }

  li+li {
    margin-left: 20px;
  }

}

.mobile {
  visibility: hidden;
  height: 0;
}

.pc {
  height: 50px;
}

/*弹窗*/
.popup-wrap {

  -webkit-transform: translate3d(0, 0, 0) scaleX(0);
  transform: translate3d(0, 0, 0) scaleX(0);

}

.popup-wrap.popup-wrap_visible {
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: translate3d(-50%, 0, 0) scaleX(1);
  transform: translate3d(-50%, 0, 0) scaleX(1);
}

.popup-mask {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.popup {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  z-index: 1;
  box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.7s ease;
  overflow: hidden;
  width: auto;

  .li {
    font-weight: 400;
    font-size: 14px;
    color: #101010;
    line-height: 14px;
    white-space: nowrap;
    display: block;
    a{display: block}
  }

  .li+.li {
    margin-top: 20px;
  }

  .li:hover {
    color: #FB471F;
  }
}

.popup.show {
  padding: 20px;
  height: auto;
  background: #fff;
}

.popup.leave {
  height: 0;
}

@media screen and (max-width: 1280px) {
  .header_nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(97, 121, 152, 0.1);

    img {
      width: 102px;
      height: auto;
    }

    li {
      padding: 0 12px;
      font-weight: 400;
      font-size: 14px;
      color: #101010;
      border-radius: 10px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      line-height: 26px;
      border: 2px solid #FFFFFF;
      position: relative;

      &:hover {
        border: 2px solid #FB471F;
        color: #FB471F;
        font-weight: 500;
      }

      &.active {
        border: 2px solid #FB471F;
        color: #FB471F;
        font-weight: 500;
      }
    }

    li+li {
      margin-left: 10px;
    }

  }
}

@media screen and (max-width: 980px) {
  .pc {
    visibility: hidden;
    height: 0;
  }

  .mobile {
    visibility: visible;
    height: 50px;
  }

  .header_nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(97, 121, 152, 0.1);

    img {
      width: 102px;
      height: auto;
    }

    .more {
      width: 45px;
      height: 45px;
      background: #FB471F;
      cursor: pointer;

      img {
        width: 24px;
        height: auto;
      }
    }

  }

  .popup1 {
    position: fixed;
    height: 100vh;
    padding-top: 60px;
    padding-bottom: 30px;
    width: 80vw;
    top: 0;
    z-index: 100;
    right: 0;
    background: #FFFFFF;

    .close {
      width: 20px;
      height: auto;
      position: absolute;
      z-index: 2;
      top: 19px;
      right: 19px;
      cursor: pointer;
    }

    .arrow {
      width: 15px;
      height: auto;
    }

    .li {
      font-size: 15px;
      color: #101010;
      padding: 10px 15px;
a{
  display: block;
}
      &.active p {
        color: #FB471F;
      }
       p.active {
        color: #FB471F;
      }
      &.active a {
        color: #FB471F;
      }
    }
  }

  .mid_item {

    font-size: 12px;
    display: block;
    .item {
      padding-top: 15px;
      padding-bottom: 5px;
    }

    &.leave {
      height: 0;
      overflow: hidden;
    }

    &.show {
      height: auto;

      padding: 0 15px;
    }
  }
}
</style>
