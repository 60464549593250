<!--
 * @author: JJ
 * @since: 2024-11-11
 * FAQ.vue
-->
<template>
  <div class="container">
    <Header></Header>
    <section>
      <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr pb70">
        <div class=" lh18">
          <div class="before_link"> <router-link to="/">Home /</router-link></div>
          <div class="before_link"><router-link to="/WarrantyCheck">Technical Center /</router-link></div>
          <div class="now_link"><router-link to="/FAQ">FAQ</router-link></div>
        </div>
        <div class="w1270 pt65">
          <div class="fs40 fwb col1 lh40">FAQ</div>
          <div class="pt70 scroll">
            <div class="tab_item" :class="nindex == index ? 'ac' : ''" @click="chooseNav(index)"
              v-for="(item, index) in tab" :key="index">{{ item.name }}</div>
          </div>
          <div class="scroll pt10">

            <div class="sub_tab_item" :class="nindex1 == index1 ? 'active' : ''" @click="chooseNav1(index1)"
              v-for="(item1, index1) in mintab" :key="index1">{{ item1.name }}</div>
          </div>
          <ul class="pt10">
            <li class="item" v-for="(item, index) in resList" :key="index">
              <div class="flex-box cp" @click="changeShow(item.id)">
                <p class="flex-grow-1 pr30 fs20">{{ item.title }}</p>
                <img src="../assets/icon/faq_del.png" v-if="multiAnswer.includes(item.id)" alt="">
                <img src="../assets/icon/faq_add.png" v-if="!multiAnswer.includes(item.id)" alt="">
              </div>
              <span class="fs16" v-if="multiAnswer.includes(item.id)">{{ item.content }}</span>
            </li>
          </ul>

          <pagination-w v-if="total > pageSize" :sumCount="total" :perCount="pageSize" @pageTurn="getList"
            ref="pagination"></pagination-w>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, inject } from "vue";
import { useHead } from "@unhead/vue";
import {apiArticle, ApiCategoryList, apiFAQ, apiGetSeconds} from "../request/api";
let nindex = ref(0)
let nindex1 =ref(0);
let tab = ref([])
let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据

let mintab=ref([]);

let multiAnswer = ref([]);

onMounted(() => {
  getCategory();
});
onBeforeMount(() => {
  getInfo()
})
const getCategory = async (e) => {
  try {
    const res = await ApiCategoryList({ type: 3 });
    if (res.data.length > 0) {
      tab.value = res.data;
      //getList()
      getSeconds();
    }

  } catch (e) {

  }
}
const getSeconds= async ()=>{
  const res = await apiGetSeconds({ type: 3, category_id: tab.value[nindex.value] ? tab.value[nindex.value].id : 0 });
  mintab.value=res.data;
  nindex1.value=0;
  getList();
}
const getList = async (e) => {
  try {
    const res = await apiFAQ({ page: e ? e + 1 : 1,
      category_id: tab.value[nindex.value] ? tab.value[nindex.value].id : 0,
      second_id:mintab.value[nindex1.value]?mintab.value[nindex1.value].id:0,
    });
    resList.value = [];
    resList.value = res.data.data;
    pageSize.value = res.data.per_page;
    total.value = res.data.total;
  } catch (e) {

  }
}
const getInfo = async () => {
  try {

    const res = await apiArticle({ title: 'FAQ' }); // 调用接口



    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const chooseNav = (index) => {
  nindex.value = index;
getSeconds()
}
const chooseNav1 = (index1) => {
  nindex1.value = index1;
  getList()
}
const changeShow = (id) => {
  if (multiAnswer.value.includes(id)) {
    multiAnswer.value.pop(id);
  } else {
    multiAnswer.value.push(id);
  }
}

</script>
<style lang="less" scoped>
.scroll {
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 8px;
}

::-webkit-scrollbar {
  display: none;
}

.tab_item {
  display: inline-block;
  vertical-align: top;
  margin-right: 40px;
  font-weight: 400;
  font-size: 20px;
  color: #555555;
  line-height: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;

  &.ac {
    font-weight: 500;
    color: #FB471F;
    font-family: Metropolis-Medium;
  }

  &.ac::after {
    content: '';
    width: 4px;
    height: 4px;
    background: #FB471F;
    border-radius: 12px;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.sub_tab_item {
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
  line-height: 35px;
  color: #898989;
  font-size: 14px;
  background: #F5F8FC;
  border-radius: 18px;
  margin-right: 20px;
  min-width: 86px;
  text-align: center;
  cursor: pointer;
  &.active{
    color: #FB471F;
  }
}

.item {
  padding: 40px 0;
  border-bottom: 1px solid rgba(251, 71, 31, 0.2);

  img {
    width: 14px;
    height: auto;
  }

  p {
    font-weight: 500;
    color: #101010;
    line-height: 20px;
    font-family: Metropolis-Medium;
  }

  span {
    display: block;
    margin-top: 35px;
    font-weight: 400;
    color: #101010;
    line-height: 26px;
  }
}
</style>
