<!--
 * @author: JJ
 * @since: 2024-11-12
 * DealerPortal.vue
-->
<template>
    <div class="container" >
        <Header></Header>
        <section v-if="detail">
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr lh18 pb70">
                <div>
                    <div class="before_link"><router-link to="/">Home /</router-link> </div>
                  <div class="now_link"><router-link to="/DealerPortal">DealerPortal</router-link></div>
                </div>
                <div class="pt65 fs40 lh40 flex-box flex-between">
                    <div class="fwb">Dealer Information</div>
                    <button class="edit_btn"><router-link to="/EditInformation">Edit</router-link></button>
                </div>
                <section class="pc">
                    <div class="pt60 flex-box top_info">
                        <div class="img_nav">
                            <img :src="detail.shop_image" alt="">
                        </div>
                        <div class="flex-grow-1 pl50 col1 fs16">
                            <p class="fs30 lh40">{{detail.shop_name}}</p>
                            <div class="mt30 flex">
                                <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.mobile}}</div>
                            </div>
                            <div class="mt10 flex">
                                <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.email}}</div>
                            </div>
                            <div class="mt10 flex bb pb40">
                                <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.address}}</div>
                            </div>
                            <div class="mt30 lh26">{{detail.desc}}</div>
                        </div>
                    </div>
                </section>
                <section class="mobile">
                    <div class="pt60 top_info">
                        <div class="img_nav">
                            <img :src="detail.shop_image"  alt="">
                        </div>
                        <div class="mt30 col1 fs16">
                            <p class="fs30 lh40">{{detail.shop_name}}</p>
                            <div class="mt30 flex">
                                <img src="../assets/icon/icon_phone1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.mobile}}</div>
                            </div>
                            <div class="mt10 flex">
                                <img src="../assets/icon/icon_email1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.email}}</div>
                            </div>
                            <div class="mt10 flex bb pb40">
                                <img src="../assets/icon/icon_address1.png" class="ico14" alt="">
                                <div class="flex-grow-1 pl10">{{detail.address}}</div>
                            </div>
                            <div class="mt30 lh26">{{detail.desc}}</div>
                        </div>
                    </div>
                </section>
            </div>
            <section class="bgf3 ptb80">
                <div class="w1270">
                    <div class="fwb fs40 col1 pt70">Branding Elements</div>
                    <ul class="w1270" id="pic">
                        <li class="picture_li fs20 lh24 col1" v-for="(item, index) in resList" :key="index">
                            <router-link :to="{ name: 'BrandingElementsInfo', query: { id: item.goods_id } }">
                                <div class="img_nav">
                                <img :src="item.good.goods_image" alt="">
                            </div>
                            <div class="mt20 m-ellipsis-l2 fwb">{{item.good.goods_name}}</div>
                            <div class="mt10 m-ellipsis-l2 fs16 col5">{{item.good.desc}}</div>
                            </router-link>
                           
                        </li>
                    </ul>
                </div>

              <pagination-w v-if="total > pageSize" :sumCount="total" :perCount="pageSize"
                            @pageTurn="getList" ref="pagination"></pagination-w>
            </section>
        </section>
      <section class="w1270 flex-box flex-center nothing" v-else>
        <h1></h1>
      </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onBeforeMount,onMounted, ref} from "vue"
import {apiBrandShops,  apiShopInfo} from "../request/api";
import {useHead} from "@unhead/vue";
import { useRouter } from 'vue-router';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const router = useRouter();
const a = ref(0)
const  detail=ref(null);
let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据
onMounted(()=>{



 getInfos();
  getList()
})

const getInfos = async () => {
  try {

    const res = await apiShopInfo({}); // 调用接口
    if(res.code!=1){
      if(res.code==401){
        if (confirm("please log in first")) {
          // 用户点击了确定
          router.push({ path: '/Login' });
        } else {
          // 用户点击了取消
          router.push({ path: '/' });
        }
      }
      return
    }
    detail.value=res.data;



    useHead({
      title: res.data.shop_name+'_JOYVIE',
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const getList = async (e) => {
  try {

    const res = await apiBrandShops({ page: e ? e + 1 : 1 });
    resList.value = [];
    resList.value = res.data.data;
    pageSize.value = res.data.per_page;
    total.value = res.data.total;
    pageNum.value = res.data.last_page;
    if(e){
      window.scrollTo(0, 0)
    }

  } catch (e) {

  }
}
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.top_info {
    .img_nav {
        width: 725px;
        height: 450px;
        overflow: hidden;
        border-radius: 10px;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: all 0.6s;
        }

        img:hover {
            transform: scale(1.4);
        }
    }

    .star {
        width: 16px;
        height: auto;
        margin-right: 5px;
    }
}

.bb {
    border-bottom: 1px solid #EEEEEE;
}

.img537 {
    width: 537px;
    height: auto;
}

.picture_li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 400px;
    margin-right: 35px;
&:hover div{color: #FB471F;}
    .img_nav {
        width: 400px;
        height: 400px;
        overflow: hidden;
        border-radius: 7px;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 7px;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.picture_li:nth-of-type(3n) {
    margin-right: 0;
}

.edit_btn {
    width: 75px;
    line-height: 45px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    background: #FB471F;
    border-radius: 12px;
    display: block;
    cursor: pointer;
}

.bgf3 {
    background: #F3F7FF;
}

@media screen and (max-width: 1280px) {
    .top_info {
        .img_nav {
            width: calc(725px * 0.7);
            height: calc(450px * 0.7);
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                transition: all 0.6s;
            }

            img:hover {
                transform: scale(1.4);
            }
        }


        .star {
            width: 16px;
            height: auto;
            margin-right: 5px;
        }
    }

    .img537 {
        width: calc(537px * 0.7);
        height: auto;
    }

    .picture_li {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        width: 400px;
        margin-right: 35px;

        img {
            width: 100%;
            height: 400px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 35px;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    #pic.w1270 {
        max-width: 835px;
    }
}

@media screen and (max-width: 980px) {
    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .mt60 {
        margin-top: 30px;
    }

    .w1000 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .w1027 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .mt80 {
        margin-top: 40px;
    }

    .pt60 {
        padding-top: 30px;
    }

    .pt70 {
        padding-top: 45px;
    }

    .pt65 {
        padding-top: 35px;
    }

    .picture_li {
        display: block;
        margin-top: 40px;
        width: 100%;
        margin-right: 0;

        .img_nav {
            width: 100%;
            height: calc(100% / 400 * 400);
            overflow: hidden;
            border-radius: 7px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 0;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    .top_info {
        .img_nav {
            width: 100%;
            height: auto;
            overflow: hidden;
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                transition: all 0.6s;
            }

            img:hover {
                transform: scale(1.4);
            }
        }


        .star {
            width: 16px;
            height: auto;
            margin-right: 5px;
        }
    }

    .img537 {
        width: 100%;
        height: auto;
    }
}
</style>
