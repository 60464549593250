<!--
 * @author: JJ
 * @since: 2024-11-07
 * PPF.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header></Header>
        <section>
            <swiper ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }"
                :autoplay="{ delay: 3000, disableOnInteraction: false }" :modules="modules" class="swiper product_s"
                loop>
                <swiper-slide v-for="(items, index) in detail.images_arr">
                    <img :src="items" class="blog_banner" alt="">
                </swiper-slide>
            </swiper>
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr fs14 lh18">
                <div>
                    <div class="before_link"> <router-link to="/">Home /</router-link></div>
                    <div class="before_link"><router-link to="/Plotter">Products /</router-link></div>
                    <div class="now_link"><router-link to="/PPF">PPF</router-link></div>
                </div>
                <div class="pt65 fwb fs40 col1 lh40">{{ detail.name }}</div>
                <div class="pc">
                    <div class="pt60 flex-box video_link pb80">
                        <div class="video_nav" v-if="detail.mini_image">
                            <video :src="detail.mini_image" v-if="detail.mini_image" @play="onPlay" @pause="onPause"
                                :controls="controls" ref="myVideo1"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo" class="play"
                                alt="">
                        </div>
                        <div class="flex-grow-1 pl50">
                            <p class="fs30">{{ detail.sub_name }}</p>
                            <span class="fs16" v-html="detail.content">

                            </span>
                        </div>
                    </div>
                </div>
                <div class="mobile">
                    <div class="pt30 video_link pb40">
                        <div class="video_nav" v-if="detail.mini_image">
                            <video :src="detail.mini_image" v-if="detail.mini_image" @play="onPlay" @pause="onPause"
                                :controls="controls" ref="myVideo"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo1" class="play"
                                alt="">
                        </div>
                        <div class="mt30">
                            <p class="fs30">{{ detail.sub_name }}</p>
                            <span class="fs16" v-html="detail.content">

                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="film_nav">
                <div class="w1270">
                    <div class="fwb fs40 col1 lh40 pb20">Paint Protection Film</div>
                    <ul>
                        <template v-for="(item, index) in detail.goods" :key="index">
                            <li :class="nindex == index ? 'active' : ''"
                                :style="{ backgroundImage: `url(${item.goods_image})` }"
                                @mouseenter="chooseItem(index, 0)">
                                <div class="tips">{{ item.goods_name }}</div>
                                <div class="moudle tc colf lh24 flex-box flex-center flex-col">
                                    <p class="fs30 medium">{{ item.goods_name }}</p>
                                    <p class="mt20 w500 fs16">{{ item.desc }}</p>
                                    <router-link class="more"
                                        :to="{ name: 'ProductInfo', query: { id: item.id } }">MORE+</router-link>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <img :src="detail.images ? detail.images : '../assets/icon/pff_mid_banner.png'" class="mid_banner" alt="">
            <div class="pt70 pb80">
                <div class="w1270">
                    <div class="fwb fs40 col1 lh40">Other Cases</div>
                    <ul>
                        <li class="case_item" v-for="(item, index) in detail.cases" :key="index">
                            <router-link :to="{ name: 'ExampleInfo', query: { id: item.id, type: 1 } }">
                                <div class="img_nav">
                                    <img :src="item.image" alt="">
                                </div>
                                <p class="m-ellipsis-l2 fs20">{{ item.title }}</p>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiArticle } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router'
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'swiper/css/effect-creative';
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
const router = useRouter()
let controls = ref(false)
let myVideo1 = ref(null)
let myVideo = ref(null)
let nindex = ref(-1)
const bg = ref(require('../assets/icon/blog_item.png'))
const chooseItem = (index, is_click) => {
    nindex.value = index;

}
const cases = ref([]);

const detail = ref(null);
onBeforeMount(() => {
    getInfos()
})

const getInfos = async () => {
    try {

        const res = await apiArticle({ title: 'PPF' }); // 调用接口

        detail.value = res.data;


        useHead({
            title: res.data.sub_title,
            meta: [
                {
                    name: "description",
                    content: res.data.desc,
                },
                {
                    name: "keywords",
                    content: res.data.keywords,
                },
            ],
        });
    } catch (error) {
        console.error(error);
    }
}
const operateVideo = () => {
    if (myVideo1.value) {

        if (controls.value) {
            //
            myVideo1.value.pause();
            controls.value = false;
        } else {
            myVideo1.value.play();

            controls.value = true;
        }
    }

}
const operateVideo1 = () => {
    if (myVideo.value) {

        if (controls.value) {
            //
            myVideo.value.pause();
            controls.value = false;
        } else {
            myVideo.value.play();

            controls.value = true;
        }
    }

}
const onPlay = () => {
    controls.value = true;
    console.log('Video is playing');
};

const onPause = () => {
    controls.value = false;
    console.log('Video is paused');
};
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.swiper {
    width: 100%;
    height: calc(100vw / 1440 * 400);
}

.ppf_banner {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.mid_banner {
    width: 100%;
    height: calc(100vw / 1440 * 620);
    object-fit: cover;
}

.watermark {
    width: 704px;
    height: auto;
    display: block;
}

.mt_10 {
    margin-top: -10px;
}

.before_link {
    color: #898989;
    display: inline-block;
}

.now_link {
    color: #555;
    display: inline-block;
}

.video_link {
    p {
        color: #101010;
        line-height: 30px;
    }

    span {
        margin-top: 30px;
        color: #101010;
        line-height: 26px;
        display: block;
    }

    .video_nav {
        position: relative;
        width: 725px;
        height: 450px;
        border-radius: 10px;

        video {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            border-radius: 10px;
        }

        .play {
            width: 65px;
            height: 45px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }
}

.film_nav {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 80px;
    background: #C1C5C8;

    li {
        width: 615px;
        height: 400px;
        border-radius: 8px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        margin-right: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .tips {
            width: 180px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            background: #FB471F;
            border-radius: 8px 10px 10px 0px;
            font-family: Metropolis-SemiBold;
            font-weight: 600;
            font-size: 30px;
            color: #FFFFFF;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

        }

        .moudle {
            width: 100%;
            height: 100%;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.60);
        }
    }

    li:hover {

        .tips {
            display: none;
        }

        .moudle {
            transition: all 0.6s;
            display: flex;
        }
    }

    li.active {
        .tips {
            display: none;
        }

        .moudle {
            transition: all 0.6s;
            display: flex;
        }
    }

    li:nth-of-type(2n) {
        margin-right: 0;
    }

    .more {
        margin-top: 40px;
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #FFFFFF;
        background: #FB471F;
        border-radius: 8px;
        cursor: pointer;
        display: block;
    }
}

.w500 {
    max-width: 500px;
}

.case_item {
    margin-top: 50px;
    margin-right: 35px;
    display: inline-block;
    width: 400px;
    vertical-align: top;
    cursor: pointer;

    .img_nav {
        width: 100%;
        height: 243px;
        border-radius: 7px;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.6s;
        object-fit: cover;
        border-radius: 7px;
    }

    img:hover {
        transform: scale(1.4);
    }

    p {
        margin-top: 20px;
        font-family: Metropolis-Medium;
        color: #101010;
        line-height: 24px;
    }

    &:hover p {
        color: #FB471F;
    }
}

.case_item:nth-of-type(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1280px) {
    .video_link {
        .video_nav {
            width: calc(725px * 0.6);
            height: calc(450px * 0.6);

        }
    }

    .film_nav {

        li {
            width: calc(615px * 0.7);
            height: calc(400px * 0.7);

            .more {
                margin-top: 20px;
                width: 120px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                color: #FFFFFF;
                background: #FB471F;
                border-radius: 8px;
            }
        }
    }

    .w500 {
        max-width: calc(500px * 0.7);
    }

    .case_item {
        margin-top: 50px;
        margin-right: 35px;
        display: inline-block;
        width: calc(400px * 0.7);
        vertical-align: top;

        .img_nav {
            height: calc(243px * 0.7);
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 7px;
        }
    }
}

@media screen and (max-width: 980px) {
    .watermark {
        width: calc(100% / 1440 * 704);
        height: auto;
        display: block;
    }

    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .video_link {
        .video_nav {
            width: 100%;
            height: calc(100% * 725 / 450);
            border-radius: 10px;
        }
    }

    .film_nav {

        li {
            width: 100%;
            height: calc(100% * 615px / 400px);
            margin-right: 0;
        }
    }

    .w500 {
        max-width: calc(100% * 0.9);
    }

    .case_item {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .case_item:nth-of-type(3n) {
        margin-right: auto;
    }
}
</style>
<style>
.product_s .swiper-pagination .swiper-pagination-bullet-active {
    background: #FB471F;
}
</style>