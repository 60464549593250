<!--
 * @author: JJ
 * @since: 2024-11-08
 * software.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header />
        <section class="pc">
            <swiper ref="swiper1" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }"
                :autoplay="{ delay: 3000, disableOnInteraction: false }" :modules="modules"
                class="blog_swiper product_s" loop>
                <swiper-slide v-for="(items, index) in detail.images_arr">
                    <img :src="items" class="blog_banner" alt="">
                </swiper-slide>
            </swiper>

            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr  lh18">
                <div class="before_link"> <router-link to="/">Home /</router-link></div>
                <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                <div class="now_link"><router-link to="/Software">Precut Software</router-link></div>
            </div>
            <div class="main_nav">
                <div class="w1270">
                    <p class="fs40 fwb col1 lh40">Precut Software</p>
                    <div class="flex-box pt60">
                        <div class="flex-grow-1 col1 pr30">
                            <p class="fs30 lh30" v-if="detail.name">{{ detail.name }}</p>
                            <div class="mt30 fs16 lh26"><span v-html="detail.content"></span></div>
                        </div>
                        <img :src="detail.mini_image" class="soft0" alt="">
                    </div>
                    <p class="fs40 fwb col1 lh40 pt110">How To Recharge</p>
                    <div class="pt70 flex-box">
                        <img :src="detail.images" class="soft1" alt="">
                        <ul class="flex-grow-1 pl50">
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step1</p>
                                <div class="fs16 lh16">{{ detail.step1 }}</div>
                            </li>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step2</p>
                                <div class="fs16 lh16">{{ detail.step2 }}</div>
                            </li>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step3</p>
                                <div class="fs16 lh16">{{ detail.step3 }}</div>
                            </li>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step4</p>
                                <div class="fs16 lh16">{{ detail.step4 }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </section>
        <section class="mobile" v-if="detail">
            <!--            <img src="../assets/icon/blog_banner.png" class="blog_banner" alt="">-->
            <swiper ref="swiper1" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }"
                :autoplay="{ delay: 3000, disableOnInteraction: false }" :modules="modules"
                class="blog_swiper product_s" loop>
                <swiper-slide v-for="(items, index) in detail.images_arr">
                    <img :src="items" class="blog_banner" alt="">
                </swiper-slide>
            </swiper>

            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr  lh18">
              <div class="before_link"> <router-link  to="/" >Home  /</router-link></div>
              <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
              <div class="now_link"><router-link to="/Software">Precut Software</router-link></div>
            </div>
            <div class="main_nav">
                <div class="w1270">
                    <p class="fs40 fwb col1 lh40">Precut Software</p>
                    <div class="pt60">
                        <div class="col1 pr30">
                            <p class="fs30 lh30" v-if="detail.name">{{ detail.name }}</p>
                            <div class="mt30 fs16 lh26"><span v-html="detail.content"></span></div>
                        </div>
                        <img :src="detail.mini_image" class="soft0" alt="">
                    </div>
                    <p class="fs40 fwb col1 lh40 pt110">How To Recharge</p>
                    <div class="pt70">
                        <img :src="detail.images" class="soft1" alt="">
                        <ul>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step1</p>
                                <div class="fs16 lh16">{{ detail.step1 }}</div>
                            </li>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step2</p>
                                <div class="fs16 lh16">{{ detail.step2 }}</div>
                            </li>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step3</p>
                                <div class="fs16 lh16">{{ detail.step3 }}</div>
                            </li>
                            <li class="step_item">
                                <p class="fs20 fwb lh20">Step4</p>
                                <div class="fs16 lh16">{{ detail.step4 }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <div class="pt65 pb90 w1270">
            <p class="fs40 fwb col1 lh40">Main Interface</p>
            <div class="flex-box mt40 flex-between">
                <img src="../assets/icon/icon_pre.png" @click="prev" class="ico47 cp" alt="">
                <swiper class="mid_swiper" :observer="true" :observeParents="true" :modules="modules" loop
                    ref="swiper3" @swiper="onSwipers">
                    <swiper-slide v-for="(item, index) in detail.resource_images"
                        :key="index">
                        <div  class="mid_swiper_item fs20 lh20 fwb">
                            <img :src="item.url" alt="" v-if="item.type == 'image'">
                        <video :src="item.url" controls v-if="item.type == 'video'"></video>
                        </div>
                    </swiper-slide>
                </swiper>
                <img src="../assets/icon/icon_next.png" @click="next" class="ico47 cp" alt="">
            </div>
        </div>
        <Footer />
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from "vue"
import { apiArticle } from "../request/api";
import { useHead } from "@unhead/vue";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];

//const value = ref('Ease of Use: The software is user-friendly, making it easy for installers to operate and learn.\n\nHigh Precision: With accurate data and cutting capabilities, Joycut enhances installation efficiency and material savings, ensuring the film adheres perfectly to the vehicle’s surface.\n\nGlobal Reach: Joycut is used in over 90 countries, meeting the market’s demands for precise, fast-cutting solutions.\n\nImproved Efficiency: Joycut helps significantly improve installation speed and precision, making it a perfect assistant for professional film installers')

const detail = ref(null);
onBeforeMount(() => {
    getInfos()
})

const getInfos = async () => {
    try {

        const res = await apiArticle({ title: 'Precut Software' }); // 调用接口

        detail.value = res.data;

        useHead({
            title: res.data.sub_title,
            meta: [
                {
                    name: "description",
                    content: res.data.desc,
                },
                {
                    name: "keywords",
                    content: res.data.keywords,
                },
            ],
        });
    } catch (error) {
        console.error(error);
    }
}
let swi=ref(null)
const onSwipers = (e) => {
    swi.value = e;
    console.log(swi.value);
    
}
const prev = () => {
    swi.value.slidePrev();
}
const next = () => {
    swi.value.slideNext()
}
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.blog_swiper {
    width: 100%;
    height: calc(100vw / 1440 * 400);
}

.blog_banner {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.watermark {
    width: 704px;
    height: auto;
    display: block;
}

.mt_10 {
    margin-top: -10px;
}

.before_link {
    color: #898989;
    display: inline-block;
}

.now_link {
    color: #555;
    display: inline-block;
}

.main_nav {
    padding-top: 65px;
    padding-bottom: 137px;
    width: 100%;
    background-image: url('../assets/icon/soft_bg.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    background-color: #FFFFFF;

    span {
        max-width: 495px;
        display: block;
    }

    .soft0 {
        width: calc(100vw / 1440 * 576);
        height: auto;
    }

    .soft1 {
        width: calc(100vw / 1440 * 540);
        height: auto;
    }

    .step_item {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 20px 30px;

        p {
            color: #FB471F;
            position: relative;
        }

        p::before {
            content: '·';
            color: #FB471F;
            position: absolute;
            top: 50%;
            left: -13px;
            transform: translateY(-50%);
        }

        div {
            color: #101010;
            margin-top: 15px;
        }
    }

    .step_item+.step_item {
        margin-top: 20px;
    }
}

.pt110 {
    padding-top: 110px;
}

.pb90 {
    padding-bottom: 90px;
}

.ico47 {
    width: 47px;
    height: auto;
}

.mid_swiper {
    width: 946px;
    height: 563px;
    &_item {
        width: 100%;
        background: #ECF0F8;
        border-radius: 10px;
        padding: 30px;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        display: block;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        display: block;
    }

    p {
        color: #101010;
        margin-top: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 1280px) {
    .mid_swiper {
        width: calc(946px * 0.7);
        height: calc(563px * 0.7);
        &_item {
            width: 100%;
            background: #ECF0F8;
            border-radius: 10px;
            padding: 30px;
        }

        img {
            width: 100%;
            height: 100%;
           
            object-fit: cover;
            border-radius: 10px;
        }

        p {
            color: #101010;
            margin-top: 20px;
            text-align: center;
        }
    }
}

@media screen and (max-width: 980px) {
    .mobile {
        height: auto;
        overflow: hidden;
    }

    .pc {
        height: 0;
        overflow: auto;
    }

    .watermark {
        width: calc(100% / 1440 * 704);
        height: auto;
        display: block;
    }

    .mt_10 {
        margin-top: 10px;
    }

    .main_nav {
        padding-top: 30px;
        padding-bottom: 40px;
        width: 100%;
        background-image: url('');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100%;
        background-color: #FFFFFF;

        span {
            max-width: 100%;
            display: block;
        }

        .soft0 {
            width: 100%;
            height: auto;
            display: block;

        }

        .soft1 {
            width: 100%;
            height: auto;
            display: block;
        }

        .step_item {
            width: 100%;
            background: #FFFFFF;
            box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            padding: 20px 30px;

            p {
                color: #FB471F;
                position: relative;
            }

            p::before {
                content: '·';
                color: #FB471F;
                position: absolute;
                top: 50%;
                left: -13px;
                transform: translateY(-50%);
            }

            div {
                color: #101010;
                margin-top: 15px;
            }
        }

        .step_item {
            margin-top: 20px;
        }
    }

    .pt110 {
        padding-top: 55px;
    }

    .pb90 {
        padding-bottom: 45px;
    }

    .pt65 {
        padding-top: 0;
    }

    .mid_swiper {
        width: 100%;
        height: calc(100% / 946 * 563);
        margin-left: 15px;
        margin-right: 15px;

        &_item {
            width: 100%;
            background: #ECF0F8;
            border-radius: 10px;
            padding: 15px;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 10px;
        }

        p {
            color: #101010;
            margin-top: 10px;
            text-align: center;
        }
    }

    .ico47 {
        width: 20px;
        height: auto;
    }
}
</style>
