<!--
 * @author: JJ
 * @since: 2024-11-07
 * CoreValues.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section>
            <img :src="image" v-if="image" class="banner" alt="">
            <div class="w1270 pt30 pb70">
                <div class="lh18">
                  <div class="before_link"> <router-link  to="/" >Home /</router-link></div>
                  <div class="before_link"><router-link  to="/blog" >About Joyvie /</router-link></div>
                    <div class="now_link"><router-link to="/coreValues">Core Values</router-link></div>
                </div>
                <div class="pt65 fwb fs40 col1 lh40">Core Values</div>
                <!-- :autoplay="{ delay: 3000, disableOnInteraction: false }" -->
                <swiper  @swiper="onSwiper"
                    @slideChange="onSlideChange" class="core_swiper" :observer="true" :observeParents="true"
                    :modules="modules" loop ref="swiper">
                    <swiper-slide class="swiper_item" v-for="(item, index) in banner" :key="index">
                        <div class="flex-box">
                            <div class="img_nav">
                                <img :src="item.image" alt="">
                            </div>
                            <div class="flex-grow-1 pl50">
                                <p class="fs30">{{item.title}}</p>
                                <span class="fs16" v-html="item.content">
                                </span>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="dot_nav">
                    <div class="dot" :class="activeIndex == index ? 'active' : ''" v-for="(item, index) in banner"
                        @click="chooseDot(index)" :key="index"></div>
                </div>
            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue"
import {apiArticle,apiGetCoreValues} from "../request/api";
import {useHead} from "@unhead/vue";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let uswiper = ref(undefined);
let activeIndex = ref(0);
let banner = ref([])
const onSwiper = (s) => {
    uswiper.value = s
    console.log(uswiper.value);
};
const onSlideChange = (swiper) => {
    console.log(swiper.realIndex);
    activeIndex.value = swiper.realIndex
};
const chooseDot = (index) => {
    uswiper.value.slideTo(index, 500)
    activeIndex.value = index
}
let image=ref('');

onMounted(() => {
  getCores()
});
const getCores = async () => {
  try {

    const res = await apiGetCoreValues(); // 调用接口


    if(res.data){
      banner.value=res.data
    }

  } catch (error) {
    console.error(error);
  }
}
onBeforeMount(()=>{
  getInfos()
})

const getInfos = async () => {
  try {

    const res = await apiArticle({title:'Core Values'}); // 调用接口

    if(res.data.image){
      image.value=res.data.image;
    }

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.banner {
    width: 100%;
    height: calc(100vw / 1440 * 400);
    object-fit: cover;
}

.before_link {
    color: #898989;
    display: inline-block;
}

.now_link {
    color: #555;
    display: inline-block;
}

.core_swiper {
    width: 100%;
    height: auto;
    margin-top: 60px;

    .img_nav {
        width: 677px;
        height: 552px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            transition: all 0.6s;
        }

        img:hover {
            transform: scale(1.4);
        }

        p {
            font-weight: 400;
            color: #101010;
            line-height: 30px;
        }

        span {
            font-weight: 400;
            color: #101010;
            line-height: 26px;
            margin-top: 30px;
            display: block;
        }
    }
}

.dot_nav {
    margin-top: 60px;
    text-align: center;
    width: 100%;

    .dot {
        width: 14px;
        height: 3px;
        background: rgba(18, 41, 66, 0.2);
        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        &.active {
            background: #122942;
        }
    }

    .dot+.dot {
        margin-left: 7px;
    }
}

@media screen and (max-width: 1280px) {
    .core_swiper {
        width: 100%;
        height: auto;
        margin-top: 60px;

        .img_nav {
            width: calc(677px * 0.6);
            height: calc(552px * 0.6);
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transition: all 0.6s;
            }

            img:hover {
                transform: scale(1.4);
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .flex-box {
        display: block;
    }

    .flex-grow-1 {
        width: 100%;
    }

    .pl50 {
        padding-left: 0;
        margin-top: 30px;
    }
    .core_swiper {
        width: 100%;
        height: auto;
        margin-top: 60px;

        .img_nav {
            width: 100%;
            height: calc( 100% * 667 / 552 );
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transition: all 0.6s;
            }

           
        }
    }
}
</style>
