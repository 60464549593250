<!--
 * @author: JJ
 * @since: 2024-11-05
 * Blog.vue
-->
<template>
  <div class="container" v-if="detail">
    <Header />
    <section>
      <img :src="detail.image" class="blog_banner" alt="">
      <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr fs14 lh18">
        <div class="before_link"><router-link to="/">Home /</router-link></div>
        <div class="before_link"><router-link to="/blog">About Joyvie /</router-link></div>
        <div class="before_link"><router-link to="/blog">Blog /</router-link></div>
        <div class="now_link" v-if="type"><router-link
            :to="{ name: 'blogs', query: { type: type } }">{{ type_name }}</router-link></div>
      </div>

      <div class="z2 mt60 pr w1270 flex-box flex-between">
        <div class="fwb fs40 col1 lh40">{{ type_name }}</div>
      </div>
      <div class="blogs_nav clearfix">
        <ul class="w1270">

          <li v-for="(item, index) in resList" :key="index">
            <router-link :to="{ name: 'blogInfo', query: { id: item.id } }" class="blogs_item">
              <div class="img_nav">
                <img :src="item.image" alt="">
              </div>
              <div class="flex-box mt20 fs14 col1">
                <div class="tips mr10" v-for="(item1, index1) in item.label_arr" :key="index1">{{ item1 }}</div>
                <div>{{ item.createtime }}</div>
              </div>
              <p class="m-ellipsis">{{ item.title }}</p>
              <span class="m-ellipsis-l2">{{ item.desc }}</span>
            </router-link>
          </li>
        </ul>
        <div class="w1270 mt30" v-if="current_page < pageNum" @click="getmore">
          <div class="more">MORE+</div>
        </div>


      </div>





    </section>
    <Footer />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from "vue"
import { apiArticle, apiNewsList } from "../request/api";
import { useHead } from "@unhead/vue";
const a = ref(0);
let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据
const type = ref('');
import { useRouter } from 'vue-router';
const router = useRouter();
const detail = ref(null);
const params = router.currentRoute.value.query;

const id = ref(0);
if (params.type) {
  type.value = params.type;
}
const type_name = ref('');
onMounted(() => {
  getList()
});
onBeforeMount(() => {
  getInfo()
})
const current_page = ref(0);
const getmore = async () => {
  if (current_page.value >= pageNum.value) {
    // functions.toast('已加载到底部了');
    return false;
  } else {

    getList();
  }
}
const getList = async () => {
  try {

    const res = await apiNewsList({ page: current_page.value + 1, type: type.value });
    // resList.value = [];
    current_page.value=current_page.value + 1;
    resList.value = resList.value.concat(res.data.blogs.data);
    pageSize.value = res.data.blogs.per_page;
    total.value = res.data.blogs.total;
    type_name.value = res.data.type_name;
    pageNum.value = res.data.blogs.last_page;

  } catch (e) {

  }
}
const getInfo = async () => {
  try {

    const res = await apiArticle({ title: 'Blog' }); // 调用接口

    detail.value = res.data;

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.blog_banner {
  width: 100%;
  height: calc(100vw / 1440 * 400);
  object-fit: cover;
}

.watermark {
  width: 704px;
  height: auto;
  display: block;
}

.mt_10 {
  margin-top: -10px;
}

.before_link {
  color: #898989;
  display: inline-block;
}

.now_link {
  color: #555;
  display: inline-block;
}

.more {
  width: 75px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #FB471F;
  border-radius: 12px;
  font-size: 14px;
  color: #FFFFFF;
  float: right;
  cursor: pointer;
}

.blogs_nav {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  background-image: url(../assets/icon/blog_bg.png);
  margin-top: -20px;
  z-index: 1;
  padding-bottom: 60px;
  padding-top: 0;

  li.title {
    display: block;
    padding-top: 70px;
    margin-top: 0;
    width: auto;
  }

  li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    margin-right: 35px;
    width: 400px;
  }

  li:nth-of-type(3n) {
    margin-right: 0;
  }
}

.blogs_item {
  width: 100%;
  height: 470px;
  background: #FFFFFF;
  box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 30px;
  display: block;

  .img_nav {
    width: 100%;
    height: 230px;
    border-radius: 10px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 230px;
    border-radius: 10px;
    object-fit: cover;
    transition: all 0.6s;
  }

  img:hover {
    transform: scale(1.4);
  }

  .tips {
    width: 62px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    text-align: center;
    background: #F0F4F7;
    border-radius: 4px;
  }

  p {
    font-weight: 500;
    font-size: 20px;
    color: #101010;
    line-height: 26px;
    font-family: "Metropolis-Medium";
    height: 52px;
    margin-top: 20px;
  }
&:hover p{
  color: #FB471F;
}
  span {
    margin-top: 10px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    color: #555555;
    line-height: 24px;
  }
  &:hover span{
  color: #FB471F;
}
}

.news_ul {
  padding-bottom: 70px;

  li.title {
    display: block;
    padding-top: 70px;
    margin-top: 0;
    width: auto;
  }

  li {
    display: inline-block;
    vertical-align: top;
    width: 292px;
    margin-right: 34px;
    margin-top: 40px;
  }

  li:nth-of-type(4n) {
    margin-right: 0;
  }

  .news_item {
    display: block;
    width: 100%;
    border-radius: 10px;

    .img_nav {
      width: 100%;
      height: 198px;
      border-radius: 10px;
      overflow: hidden;
    }

    img:hover {
      transform: scale(1.4);
    }

    img {
      width: 100%;
      height: 198px;
      object-fit: cover;
      transition: all 0.6s;
      border-radius: 7px;
    }

    .tips {
      width: 62px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-align: center;
      background: #F0F4F7;
      border-radius: 4px;
    }

    p {
      height: 20px;
      font-family: Metropolis-Medium;
      font-weight: 500;
      font-size: 20px;
      color: #101010;
      line-height: 20px;
      margin-top: 20px;
    }

    span {
      margin-top: 10px;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      color: #555555;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .blogs_nav {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-image: url(../assets/icon/blog_bg.png);
    margin-top: -20px;
    z-index: 1;
    padding-bottom: 60px;
    padding-top: 0;

    li {
      display: inline-block;
      vertical-align: top;
      margin-top: 40px;
      margin-right: 40px;
      width: 400px;
      margin-left: 30px;
    }

    li:nth-of-type(3n) {
      margin-right: 40px;
    }
  }

  .blogs_item {
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 30px;
    display: block;

    img {
      width: 100%;
      height: 230px;
      border-radius: 10px;
      object-fit: cover
    }

    .tips {
      width: 62px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-align: center;
      background: #F0F4F7;
      border-radius: 4px;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      color: #101010;
      line-height: 26px;
      font-family: "Metropolis-Medium";
      height: 52px;
      margin-top: 20px;
    }

    span {
      margin-top: 10px;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      color: #555555;
      line-height: 24px;
    }
  }

  .news_ul {
    padding-bottom: 70px;

    li {
      display: inline-block;
      vertical-align: top;
      width: 292px;
      margin-right: 34px;
      margin-top: 40px;
    }

    li:nth-of-type(4n) {
      margin-right: 34px;
    }

    li:nth-of-type(3n) {
      margin-right: 0px;
    }

    .news_item {
      display: block;
      width: 100%;

      img {
        width: 100%;
        height: 198px;
        object-fit: cover;
        border-radius: 7px;
      }

      .tips {
        width: 62px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        text-align: center;
        background: #F0F4F7;
        border-radius: 4px;
      }

      p {
        height: 20px;
        font-family: Metropolis-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #101010;
        line-height: 20px;
        margin-top: 20px;
      }

      span {
        margin-top: 10px;
        height: 48px;
        font-weight: 400;
        font-size: 16px;
        color: #555555;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .watermark {
    width: calc(100% / 1440 * 704);
    height: auto;
    display: block;
  }

  .mt_10 {
    margin-top: 10px;
  }

  .blogs_nav {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-image: url(../assets/icon/blog_bg.png);
    margin-top: -20px;
    z-index: 1;
    padding-bottom: 40px;
    padding-top: 0;

    li {
      display: inline-block;
      vertical-align: top;
      margin-top: 20px;
      margin-right: auto;
      width: 100%;
      margin-left: auto;
    }

    li:nth-of-type(3n) {
      margin-right: auto;
    }
  }

  .blogs_item {
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 30px;
    display: block;

    img {
      width: 100%;
      height: 230px;
      border-radius: 10px;
      object-fit: cover
    }

    .tips {
      width: 62px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-align: center;
      background: #F0F4F7;
      border-radius: 4px;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      color: #101010;
      line-height: 26px;
      font-family: "Metropolis-Medium";
      height: 52px;
      margin-top: 20px;
    }

    span {
      margin-top: 10px;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      color: #555555;
      line-height: 24px;
    }
  }

  .news_ul {
    padding-bottom: 70px;

    li {
      display: block;
      width: 292px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 40px;
    }

    li:nth-of-type(4n) {
      margin-right: auto;
    }

    li:nth-of-type(3n) {
      margin-right: auto;
    }

    .news_item {
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 198px;
        object-fit: cover;
        border-radius: 7px;
      }

      .tips {
        width: 62px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        text-align: center;
        background: #F0F4F7;
        border-radius: 4px;
      }

      p {
        height: 20px;
        font-family: Metropolis-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #101010;
        line-height: 20px;
        margin-top: 20px;
      }

      span {
        margin-top: 10px;
        height: 48px;
        font-weight: 400;
        font-size: 16px;
        color: #555555;
        line-height: 24px;
      }
    }
  }
}
</style>
