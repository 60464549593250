<!--
 * @author: JJ
 * @since: 2024-11-01
 * footer.vue
-->
<template>
  <footer>
    <div class="footer_nav">
      <div class="w1270">
        <div class="flex-box foot_top">
          <div class="flex-grow-1">
            <img src="../assets/icon/foot_logo.png" class="logo" alt="">
          </div>
          <template v-for="(item, index) in footInfo.apps">
          <a :href="item.url" target="_blank" >
            <img :src="item.image" alt="">
          </a>

          </template>
<!--          <a href="">-->
<!--            <img src="../assets/icon/foot_icon2.png" alt="">-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="../assets/icon/foot_icon3.png" alt="">-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="../assets/icon/foot_icon4.png" alt="">-->
<!--          </a>-->
<!--          <a href="">-->
<!--            <img src="../assets/icon/foot_icon5.png" alt="">-->
<!--          </a>-->
        </div>
        <div class="foot_middle flex pc">
          <ul class="first it">

               <li><router-link to="/aboutUs">ABOUT US</router-link></li>
               <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/ExampleList">Cases Gallery</router-link> </li>
              <li><router-link to="/Blog">Blog</router-link></li>

            <li><router-link to="/Training">Training</router-link></li>
          </ul>
          <div class="flex-grow-1 it">
            <ul class="mlra">
              <li><router-link to="/PPF">PRODUCTS</router-link></li>
              <li><router-link to="/PPF">PPF</router-link></li>
              <li><router-link to="/Software">Precut Software</router-link></li>
              <li><router-link to="/Plotter">Plotter</router-link></li>

              <li><router-link to="/Tools">Marketing Material</router-link></li>
            </ul>
          </div>
          <div class="flex-grow-1 it">
            <ul class="mlra">
              <li>HELP</li>
              <li><router-link to="/FAQ">FAQ</router-link></li>
              <li>Technical Support</li>
              <li>Product Specifications</li>
              <li><router-link to="/WarrantyCheck">Warranty Information</router-link></li>
            </ul>
          </div>

          <ul class="last it">
            <li><router-link to="/ContactUs">Contact Us</router-link></li>
            <li><router-link to="/ContactUs">+86 {{footInfo.contact_number}}</router-link></li>
            <li><router-link to="/ContactUs">{{footInfo.enterprise_email}}</router-link></li>
            <li><router-link to="/ContactUs">Become an installer</router-link></li>
            <li><router-link to="/PartnerSearch">Find Joyvie near you</router-link></li>
            <li><router-link to="/BecomePartner">Join Us</router-link></li>
          </ul>
        </div>
        <div class="foot_middle mobile">
          <ul class="w50 it">


            <li><router-link to="/aboutUs">ABOUT US</router-link></li>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/ExampleList">Cases Gallery</router-link></li>
            <li><router-link to="/Blog">Blog</router-link></li>
            <li><router-link to="/Training">Training</router-link></li>
          </ul>
          <ul class="w50 it">

            <li><router-link to="/PPF">PRODUCTS</router-link></li>
            <li><router-link to="/PPF">PPF</router-link></li>
           <li><router-link to="/Software">Precut Software</router-link> </li>
            <li><router-link to="/Plotter">Plotter</router-link></li>

            <li><router-link to="/Tools">Marketing Material</router-link></li>
          </ul>

          <ul class="w50 it">
            <li>HELP</li>
            <li><router-link to="/FAQ">FAQ</router-link></li>
            <li>Technical Support</li>
            <li>Product Specifications</li>
          <li><router-link to="/WarrantyCheck">Warranty Information</router-link></li>
          </ul>

          <ul class="w50 it">
            <li><router-link to="/ContactUs">Contact Us</router-link></li>
            <li><router-link to="/ContactUs">+86 {{footInfo.contact_number}}</router-link></li>
            <li><router-link to="/ContactUs">{{footInfo.enterprise_email}}</router-link></li>
            <li><router-link to="/ContactUs">Become an installer</router-link></li>
            <li><router-link to="/PartnerSearch">Find Joyvie near you</router-link></li>
            <li><router-link to="/BecomePartner">Join Us</router-link></li>
          </ul>


        </div>
        <div class="foot_bottom flex-box pc">
          <div class="flex-grow-1 flex-box cp tl">
            <img src="../assets/icon/icon_gla.png" alt="">
            <div>GLOBAL WEBSITE</div>
          </div>
          <div class="flex-grow-1 flex-box cp">
            <img src="../assets/icon/icon_add.png" alt="">
            <div>FOLLOW US ON SOCIAL</div>
          </div>
          <div class="cp flex-grow-1">LEGAL NOTES AND PRIVACY POLICIES</div>
          <div class="cp flex-grow-1 tr">COOKIES SETTINGS</div>
        </div>
        <div class="foot_bottom mobile">
          <div class=" cp tl flex-box">
            <img src="../assets/icon/icon_gla.png" alt="">
            <div>GLOBAL WEBSITE</div>
          </div>
          <div class="cp flex-box mt20">
            <img src="../assets/icon/icon_add.png" alt="">
            <div>FOLLOW US ON SOCIAL</div>
          </div>
          <div class="cp mt20">LEGAL NOTES AND PRIVACY POLICIES</div>
          <div class="cp mt20">COOKIES SETTINGS</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from "vue";
import {apiBaseInfomation, getFooter} from "../request/api";
import { useRouter } from "vue-router";
const api = inject('api'); // 使用inject来注入接口
const a = ref(0)
onMounted(() => {
  getInfo()
});
const router = useRouter()
let footInfo = ref({'contact_number':'','enterprise_email':'','apps':[]});
const getInfo = async () => {
  try {
    if (localStorage.getItem('footInfo')) {
      footInfo.value = JSON.parse(localStorage.getItem('footInfo'));
    }
    const res = await getFooter(); // 调用接口
    localStorage.setItem('footInfo', JSON.stringify(res.data));
    footInfo.value = res.data;
  } catch (error) {
    console.error(error);
  }
}
const toPage =(url)=> {
 router.push(url);
};
</script>
<style lang="less" scoped>
.footer_nav {
  background: #122942;

  li {
    cursor: pointer;
  }

  .foot_top {
    padding: 40px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    img {
      width: 16px;
      height: auto;
    }

    .logo {
      width: 99px;
      height: auto;
    }

    a+a {
      margin-left: 24px;
    }
  }

  .foot_middle {
    
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.pc {
      overflow: auto;
      height: auto;
      padding-top: 40px;
    padding-bottom: 45px;
    }

    &.mobile {
      overflow: hidden;
      height: 0;
      padding: 0;
    }

    .first {
      width: 236px;
      padding-left: 30px;
    }

    .last {
      width: 280px;
      text-align: right;
      padding-right: 30px;
    }

    ul {
      font-size: 12px;
      color: #FFFFFF;
      line-height: 12px;

    }

    li+li {
      margin-top: 20px;
    }

    .it {
      position: relative;

      ul {
        width: fit-content;
      }
      li:hover{color: #FB471F;}
    }

    .it+.it::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 148px;
      background: rgba(255, 255, 255, 0.1);
      top: 0;
      left: 0;
    }
  }

  .foot_bottom {
    padding: 25px 30px;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
&.pc{display: flex;}
&.mobile{display: none;}
    img {
      width: 14px;
      height: auto;
      margin-right: 5px;
    }
    a:hover{color: #FB471F;}
  }
}

@media screen and (max-width: 980px) {
  .footer_nav {
    .foot_top {
      padding: 40px 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      img {
        width: 16px;
        height: auto;
      }

      .logo {
        width: 99px;
        height: auto;
      }

      a+a {
        margin-left: 24px;
      }
    }

    .foot_middle {
      padding-top: 40px;
      padding-bottom: 70px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &.mobile {
        overflow: auto;
        height: auto;
        padding: 20px 0 20px;
      }

      &.pc {
        overflow: hidden;
        height: 0;
        padding: 0;
      }

      .first {
        width: 236px;
        padding-left: 30px;
      }

      .last {
        width: 280px;
        text-align: right;
        padding-right: 30px;
      }

      ul {
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;

      }

      li+li {
        margin-top: 20px;
      }

      .it {
        position: relative;
        margin-top: 40px;
        padding-left: 15px;
      }

      .it+.it::before {
        display: none;
      }

      .it:nth-of-type(2n):before {
        content: '';
        position: absolute;
        width: 1px;
        height: 148px;
        background: rgba(255, 255, 255, 0.1);
        top: 0;
        left: 0;
        display: block;
      }

      .it:nth-of-type(-n+2) {
        margin-top: 0;
      }
    }

    .foot_bottom {
      padding: 25px 15px;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
      text-align: left;
      &.pc{display: none;}
      &.mobile{display: block;}
      img {
        width: 14px;
        height: auto;
        margin-right: 5px;
      }
      a{display: flex;}
    }
  }

  .w50 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
}
</style>
