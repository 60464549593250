<!--
 * @author: JJ
 * @since: 2024-12-02
 * WarrantySearch.vue
-->
<template>
    <div>
        <Header></Header>
        <section class="container pc">
            <img src="../assets/icon/warranty_top_bg.png" class="blog_banner" alt="">
            <div class="search_link">
                <div class="w1270 flex-box flex-center">
                    <input type="text" class="inp_nav medium" placeholder="Input your warranty ID / email / Plate No.">
                    <button class="btn_search medium">search</button>
                </div>
            </div>
        </section>
        <section class="container mobile">
            <img src="../assets/icon/warranty_top_bg.png" class="blog_banner" alt="">
            <div class="search_link">
                <div class="w1270">
                    <input type="text" class="inp_nav medium" placeholder="Input your warranty ID / email / Plate No.">
                    <button class="btn_search medium">search</button>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref } from "vue"
const a = ref(0)
</script>
<style lang="less" scoped>
.pc{display: block;}
.mobile{display: none;}
.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.container {
    background-color: #eeeeec;
}

.search_link {
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-image: url('../assets/icon/warranty_bg.png');
    background-repeat: no-repeat;
    padding-top: 110px;
    padding-bottom: 260px;

    .inp_nav {
        width: 425px;
        height: 57px;
        background: #FFFFFF;
        border-radius: 18px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 14px;
    }

    .btn_search {
        width: 158px;
        line-height: 57px;
        text-align: center;
        background: #EA5520;
        border-radius: 18px;
        font-size: 21px;
        color: #FFFFFF;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;
    }
}

input::placeholder {
    color: #C0C6C8;
}
@media screen and (max-width: 980px) {
    .pc{display: none;}
.mobile{display: block;}
    .search_link {
   
    padding-top: 55px;
    padding-bottom: 130px;

    .inp_nav {
        width: 100%;
        height: 57px;
        border-radius: 18px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
    }

    .btn_search {
        width: 158px;
        line-height: 48px;
        border-radius: 16px;
        font-size: 16px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        display: block;
    }
}
}
</style>
