<!--
 * @author: JJ
 * @since: 2024-11-06
 * BlogInfo.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header></Header>
        <section>
            <div class="w1270 mt30  router">
                <div class="col89 pr10"><router-link  to="/" >Home /</router-link></div>
              <div class="col89 pr10"><router-link  to="/blog" >About Joyvie /</router-link></div>
                <div class="col89 pr10"><router-link  to="/blog" >Blog</router-link>  </div>
<!--                <div class="col5">{{detail.title}}</div>-->
            </div>
            <div class="w1270 pt65 pb80">
                <h1>{{detail.title}}</h1>
                <div class="flex-box mt35  col1">
                  <div class="tips" v-for="(items, index1) in detail.label_arr">{{items}}</div>
                    <div>{{detail.createtime}}</div>
                </div>
                <section>
                    <!-- 富文本空间 -->
                    <div class="rich_nav fs16" v-html="detail.content"></div>
                </section>
            </div>
            <div class="related_nav" v-if="detail.related.length>0">
                <div class="w1270">
                    <div class="fwb fs40 col1 lh40">Related Blogs</div>
                    <ul class="pt30">
                        <li v-for="(item, index) in detail.related" :key="index">
                            <router-link :to="{name: 'blogInfo', query: {id:item.id}}" class="blogs_item">
                                <div class="img_nav">
                                    <img src="../assets/icon/blog_item.png" alt="">
                                </div>
                                <div class="flex-box mt20 col1">
                                  <div class="tips mr10" v-for="(items, index1) in item.label_arr">{{items}}</div>
                                    <div>{{item.createtime}}</div>
                                </div>
                                <p class="m-ellipsis-l2">{{item.title}}
                                </p>
                                <span class="m-ellipsis-l2">{{item.desc}}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue";
import { useRouter } from 'vue-router';
const router = useRouter();
import {useHead} from "@unhead/vue";
import {apiNewsDetail} from "../request/api";
const a = ref(0);
const params=router.currentRoute.value.query;
const query=router.currentRoute.value;

const id=ref(0);
if(params.id){
  id.value=params.id;
}
const detail=ref(null);

onBeforeMount(()=>{


  getInfos()
})

const getInfos = async () => {
  try {

    const res = await apiNewsDetail({id:id.value}); // 调用接口

    if(res.data){
      detail.value=res.data;
    }

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.router div {
    display: inline-block;
    vertical-align: top;
}

h1 {
    font-family: Metropolis-Medium;
    font-size: 36px;
    color: #101010;
    line-height: 44px;
}

.tips {
    width: 62px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #101010;
    background: #F0F4F7;
    border-radius: 4px;
    margin-right: 10px;
}

.rich_nav {
  margin-top: 40px;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.related_nav {
    width: 100%;
    background: #F3F7FF;
    padding-top: 70px;
    padding-bottom: 80px;

    li {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        margin-right: 35px;
        width: 400px;
    }

    li:nth-of-type(3n) {
        margin-right: 0;
    }

    .blogs_item {
        width: 100%;
        height: 470px;
        background: #FFFFFF;
        box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 30px;
        display: block;

        img {
            width: 100%;
            height: 230px;
            border-radius: 10px;
            object-fit: cover
        }

        .tips {
            width: 62px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            text-align: center;
            background: #F0F4F7;
            border-radius: 4px;
        }

        p {
            font-weight: 500;
            font-size: 20px;
            color: #101010;
            line-height: 26px;
            font-family: "Metropolis-Medium";
            height: 52px;
            margin-top: 20px;
        }

        span {
            margin-top: 10px;
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            color: #555555;
            line-height: 24px;
        }
    }
}
@media screen and (max-width: 1280px) {
    .related_nav {
    width: 100%;
    background: #F3F7FF;
    padding-top: 70px;
    padding-bottom: 80px;
    li {
      display: inline-block;
      vertical-align: top;
      margin-top: 40px;
      margin-right: 40px;
      width: 400px;
      margin-left: 30px;
    }

    li:nth-of-type(3n) {
      margin-right: 40px;
    }
    .blogs_item {
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 30px;
    display: block;
.img_nav{
    width: 100%;
      height: 230px;
      border-radius: 10px;
      overflow: hidden; 
}
    img {
      width: 100%;
      height: 230px;
      border-radius: 10px;
      object-fit: cover;
      transition: all 0.6s;
    }
    img:hover {
      transform: scale(1.4);
    }
    .tips {
      width: 62px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-align: center;
      background: #F0F4F7;
      border-radius: 4px;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      color: #101010;
      line-height: 26px;
      font-family: "Metropolis-Medium";
      height: 52px;
      margin-top: 20px;
    }

    span {
      margin-top: 10px;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      color: #555555;
      line-height: 24px;
    }
  }
}
}
@media screen and (max-width: 980px) {
    .related_nav {
        width: 100%;
        background: #F3F7FF;
        padding-top: 70px;
        padding-bottom: 80px;

        li {
            display: inline-block;
            vertical-align: top;
            margin-top: 20px;
            margin-right: auto;
            width: 100%;
            margin-left: auto;
        }

        li:nth-of-type(3n) {
            margin-right: auto;
        }
    }

    .blogs_item {
        width: 100%;
        height: 470px;
        background: #FFFFFF;
        box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 30px;
        display: block;

        img {
            width: 100%;
            height: 230px;
            border-radius: 10px;
            object-fit: cover
        }

        .tips {
            width: 62px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            text-align: center;
            background: #F0F4F7;
            border-radius: 4px;
        }

        p {
            font-weight: 500;
            font-size: 20px;
            color: #101010;
            line-height: 26px;
            font-family: "Metropolis-Medium";
            height: 52px;
            margin-top: 20px;
        }

        span {
            margin-top: 10px;
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            color: #555555;
            line-height: 24px;
        }
    }
}
</style>
