<!--
 * @author: JJ
 * @since: 2024-11-01
 * fixed.vue
-->
<template>
  <div class="container"></div>
</template>

<script setup>
import {ref} from "vue"
const a = ref(0)
</script>
<style lang="less" scoped></style>
