<!--
 * @author: JJ
 * @since: 2024-11-11
 * BecomePartner.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section>
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr pb70">
                <div class=" lh18">
                    <div class="before_link"> <router-link to="/">Home /</router-link></div>
                  <div class="before_link"><router-link  to="/ParnerSearch" >Network /</router-link></div>
                  <div class="now_link"><router-link  to="/BecomePartner" >Become Partner</router-link></div>
                </div>
                <div class="w580 col1 pt65">
                    <div class="tc fs40 fwb lh40">Become Partner</div>
                    <div class="mt10  col5 lh20 tc pb20"><span v-html="subtitle"></span></div>
                    <div class="search_nav  flex-box">
                        <div>Name</div>
                        <input type="text" v-model="username" class="flex-grow-1 pl30 tr" placeholder="Please fill in your name">
                    </div>
                    <div class="search_nav  flex-box">
                        <div>Telephone</div>
                        <input type="text" v-model="phone" class="flex-grow-1 pl30 tr" placeholder="Please fill in your telephone">
                    </div>
                    <div class="search_nav  flex-box">
                        <div>Country</div>
                      <el-select v-model="nation_id" @change="getCitys" placeholder="Please select  your country" class="custom-select flex-grow-1">
                        <el-option v-for="item in nations" :key="item.id" :label="item.name"
                                   :value="item.id" />
                      </el-select>
<!--                        <input type="text" class="flex-grow-1 pl30 tr" placeholder="Please select your country">-->
                    </div>
                    <div class="search_nav  flex-box">
                        <div>Region</div>
                        <el-select v-model="city_id" placeholder="Please select  your City" class="custom-select flex-grow-1">
                            <el-option v-for="item in citys" ::key="item.id" :label="item.name"
                                       :value="item.id" />
                        </el-select>
                        <!-- <input type="text" class="flex-grow-1 pl30 tr" placeholder="Please fill in your City"> -->
                    </div>
                    <div class="textarea_nav ">
                        <div>Explain</div>
                        <textarea  placeholder="Please enter your note description"  cols="30"
                                   v-model="explains"
                            rows="10"></textarea>
                    </div>
                    <button class="submit_btn" @click="saveInfo">Submit</button>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onMounted, ref, onBeforeMount} from "vue"



// 强制更新

import {apiJoinUs,apiCountryList,apiCityList,apiArticle} from "../request/api";
import {useHead} from "@unhead/vue";
const subtitle = ref('Please fill in the following information carefully so that we can cooperate better')
const value = ref('')
const nations =ref([]);
const citys= ref([]);
const explains=ref('');
const username=ref('');
const phone=ref('');
const nation_id=ref('');
const city_id=ref('');
onMounted(() => {
  getNations();
});
const getNations= async ()=>{
  const res=await apiCountryList();
  nations.value=res.data;

}
onBeforeMount(()=>{
  getInfos()
})

const getInfos = async () => {
  try {

    const res = await apiArticle({title:'Become Partner'}); // 调用接口

    detail.value=res.data;

    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const getCitys =async (e)=>{

const res1=await apiCityList({country_id:nation_id.value});
city_id.value='';
citys.value=res1.data;
}
const saveInfo = async () => {
  try {

    if(username.value==''){
      alert('Please fill in your name');
      return;
    }
    if(phone.value==''){
      alert('Please fill in your telephone');
      return;
    }

    if(nation_id.value==''){
      alert('Please select  your country');
      return;
    }
    if(city_id.value==''){
      alert('Please select your Region');
      return;
    }
    // if(explains.value===''){
    //   alert('Please enter your note description');
    //   return;
    // }

    let data = {
      username: username.value,
      phone:phone.value,
      country: nation_id.value,
      city:city_id.value,
      explain: explains.value,
    };

    const res = await apiJoinUs(data); // 调用接口


    alert(res.msg)
    if (res.code == 1) {
      username.value='';
      phone.value= '';
      nation_id.value='';
      city_id.value='';
      explains.value='';

    }
  } catch (error) {

    console.error(error);
  }
}

</script>
<style lang="less" scoped>
.w580 {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}

.search_nav {
    margin-top: 30px;
    width: 100%;
    height: 60px;
    border-radius: 18px;
    border: 1px solid #333333;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
}

.textarea_nav {
    width: 100%;
    height: 243px;
    border-radius: 18px;
    border: 1px solid #333333;
    padding: 20px;
    margin-top: 30px;
font-size: 16px;
    textarea {
        height: 180px;
        width: 100%;
        padding-top: 20px;
        border: none;
        resize: none;
    }
}

.submit_btn {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    width: 260px;
    line-height: 70px;
    background: #FB471F;
    border-radius: 18px;
    margin-top: 40px;
    display: block;
}
</style>
<style scoped>
.custom-select {
    width: 320px;
   
    display: inline-block;
    vertical-align: top;
    text-align: right;
  

}

::v-deep.custom-select.el-select .el-select__wrapper {
    font-size: 16px;
   
    box-shadow: none;
    padding: 0;
    text-align: right;
  
    color: #333;
}

.custom-select .el-input__inner {
   
}
.custom-select.el-select {
  --el-select-input-font-size: 16px;
  font-family: "Metropolis-Regular";
  font-weight: normal;
  --el-text-color-placeholder:#777;
  --el-text-color-regular:#333;
}
</style>