<!--
 * @author: JJ
 * @since: 2024-11-07
 * ProductInfo.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section>
            <img src="../assets/icon/top.png" class="blog_banner" alt="">
            <div class="bg ">
                <div class="w1100 pt40 pr lh18">
                    <div>
                        <div class="before_link"> <router-link to="/">Home /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                        <div class="before_link"><router-link to="/PPF">PPF </router-link> </div>
                    </div>
                    <div class="mt70 fs26 semibold col152">Product Information</div>
                    <div class="tab_nav mt50 m-table">
                        <div class="tab_item" v-for="(item, index) in tab" :key="index">
                            <div class="content flex-box flex-center">
                                <span class="tag">{{ item.tag }}</span>
                                <p class=" right fwb"><span>{{ item.title }}</span></p>
                            </div>
                            <template v-if="item.list.length > 0">
                                <ul class="list">
                                    <li v-for="(items, indexs) in item.list" :key="indexs"><router-link>{{ items
                                            }}</router-link></li>
                                </ul>
                            </template>
                        </div>

                    </div>
                    <div class="mt70 flex-box info pb100">
                        <div class="cover">
                            <img src="../assets/icon/new_cover.png" alt="">
                        </div>
                        <div class="flex-grow-1 pl70 content">
                            <p class="fwb">Versatile<br>Series</p>
                            <div>Versatile Series features high-quality cost-efficient products designed for diverse
                                applications and
                                environments, seamlessly adapting to your evolving needs.</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="video_link">
                <div class="w1100">
                    <div class="video_item">
                        <div class="video_nav">
                            <video :src="videourl0" @play="onPlay(0)" @pause="onPause(0)" :controls="controls0"
                                ref="myVideo0"></video>
                            <img src="../assets/icon/play1.png" v-if="!controls0" @click="operateVideo(0)" class="play"
                                alt="">
                        </div>
                        <p>Heat Healing</p>
                    </div>
                    <div class="video_item">
                        <div class="video_nav">
                            <video :src="videourl1" @play="onPlay(1)" @pause="onPause(1)" :controls="controls1"
                                ref="myVideo1"></video>
                            <img src="../assets/icon/play1.png" v-if="!controls1" @click="operateVideo(1)" class="play"
                                alt="">
                        </div>
                        <p>Hydrophobicity</p>
                    </div>
                    <div class="video_item">
                        <div class="video_nav">
                            <video :src="videourl2" @play="onPlay(2)" @pause="onPause(2)" :controls="controls2"
                                ref="myVideo2"></video>
                            <img src="../assets/icon/play1.png" v-if="!controls2" @click="operateVideo(2)" class="play"
                                alt="">
                        </div>
                        <p>Anti-stain</p>
                    </div>
                    <div class="video_item">
                        <div class="video_nav">
                            <video :src="videourl3" @play="onPlay(3)" @pause="onPause(3)" :controls="controls3"
                                ref="myVideo3"></video>
                            <img src="../assets/icon/play1.png" v-if="!controls3" @click="operateVideo(3)" class="play"
                                alt="">
                        </div>
                        <p>Easy-Install</p>
                    </div>
                </div>
            </div>
            <div class="product_nav">
                <div class="w1100">
                    <i></i>
                    <div class="info_nav">
                        <img src="../assets/icon/v7.png" alt="">
                        <div class="flex mt50 col152 lh19">
                            <div>Note:</div>
                            <div class="flex-grow-1">
                                <p>1. All the above values are nominal and should not be used for specification purposes
                                </p>
                                <p>2. For more technical information, please visit www.joyvie.com.</p>
                            </div>
                        </div>
                    </div>
                    <p class="pt100 fs26 col152 semibold">Product Structure</p>
                    <img src="../assets/icon/img1.png" class="img1" alt="">
                </div>
            </div>
            <img src="../assets/icon/img2.png" class="bottom_img" alt="">
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { apiGetPPFDetail } from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
const router = useRouter();
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [Navigation, Pagination, Scrollbar, Autoplay];
let nindex = ref(-1)
const tab = ref([{
    tag: 'J',
    title: 'Jewel\nSeries',
    list: []
}, {
    tag: 'O',
    title: 'Outstanding \n Series',
    list: ['O8', 'O7', 'O7M']
}, {
    tag: 'Y',
    title: 'Yielding\n Series',
    list: []
}, {
    tag: 'V',
    title: 'Versatile \n Series',
    list: ['V8', 'V7', 'V7S']
}, {
    tag: 'I',
    title: 'Inspiration\nSeries',
    list: []
}, {
    tag: 'E',
    title: 'Exclusive\nSeries',
    list: ['ECO']
},])
let videourl0 = ref(require('../assets/videos/v1.mp4'))
let videourl1 = ref(require('../assets/videos/v2.mp4'))
let videourl2 = ref(require('../assets/videos/v3.mp4'))
let videourl3 = ref(require('../assets/videos/v4.mp4'))
let myVideo0 = ref(null)
let myVideo2 = ref(null)
let myVideo3 = ref(null)
let myVideo1 = ref(null)
let controls0 = ref(false)
let controls1 = ref(false)
let controls2 = ref(false)
let controls3 = ref(false)
const operateVideo = (index) => {
    if (index == 0) {
        myVideo0.value.play();
        controls0.value = false;
    }

    if (index == 1) {
        myVideo1.value.play();
        controls1.value = false;
    }

    if (index == 2) {
        myVideo2.value.play();
        controls2.value = false;
    }

    if (index == 3) {
        myVideo3.value.play();
        controls3.value = false;
    }


}

const onPlay = (index) => {
    if (index == 0) {

        controls0.value = true;
    }

    if (index == 1) {

        controls1.value = true;
    }

    if (index == 2) {

        controls2.value = true;
    }

    if (index == 3) {

        controls3.value = true;
    }

};

const onPause = (index) => {
    if (index == 0) {
        controls0.value = false;
    }

    if (index == 1) {
        controls1.value = false;
    }

    if (index == 2) {
        controls2.value = false;
    }

    if (index == 3) {
        controls3.value = false;
    }
};


</script>
<style lang="less" scoped>
.container {
    background-color: #ECEEEE;
}

.blog_banner {
    width: 100%;
    height: calc(100vw / 1440 * 313);
    object-fit: cover;
}

.before_link {

    a {
        color: #152F4A;
    }
}
.before_link:hover a{
  color: #FB471F;
}
.w1100 {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.bg {
    width: 100%;
    background-image: url('../assets/icon/bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: repeat-y;
}

.pc {
    height: auto;
    overflow: hidden;
}

.mobile {
    height: 0;
    overflow: auto;
}

.tab_nav {
    .tab_item {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        width: 175px;
        background: #C0C6C8;
        border-radius: 36px;
        cursor: pointer;
        position: relative;

        &:nth-of-type(6n) {
            margin-right: 0;
        }

        .list {
            display: none;
        }

        .content {
            width: 175px;
            height: 73px;
            position: relative;
            z-index: 2;

            img {

                width: 31px;
                height: 31px;
                object-fit: cover;
                display: block;
            }

            .right {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #152F4A;
                height: 29px;
                line-height: 17px;
                color: #152F4A;
            }
        }

        &:hover {
            background: #FFFFFF;

        }

        &:hover .list {
            display: block;
            padding-left: 35px;
            padding-right: 20px;
            padding-bottom: 25px;
            padding-top: 5px;
            position: absolute;
            top: 73px;
            left: 0;
            right: 0;
            background: #FFFFFF;
            border-radius: 0 0 36px 36px;
        }

        &:hover .list::before {
            content: '';
            background: #FFFFFF;
            top: -36px;
            width: 100%;
            left: 0;
            height: 36px;
            position: absolute;
            z-index: 1;
        }

        li {

            position: relative;

            a {
                display: inline-block;
                border-bottom: 1px solid #152F4A;
                line-height: 22px;
                padding-right: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        li:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 3px;
            height: 3px;
            background: #152F4A;
            border-radius: 2px;

        }
        .tag{
            width: 31px;
           display: block;
line-height: 31px;
text-align: center;
font-size: 25px;
color: #FFFFFF;
background: #152F4A;
border-radius: 50%;
font-family: 'Metropolis-SemiBold';
        }
    }
}

.cover {
    width: 504px;
    height: 582px;
    border-radius: 23px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 23px;
        object-fit: cover;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.info {
    p {
        font-size: 82px;
        color: #152F4A;
        line-height: 90px;
    }

    div {
        margin-top: 75px;
        font-weight: 400;
        font-size: 27px;
        color: #152F4A;
        line-height: 40px;
    }
}

.pb100 {
    padding-bottom: 145px;
}

.video_link {
    border-radius: 8px 8px 0 0;
    position: relative;
    width: 100%;
    background: #fff;
    padding-top: 112px;
    padding-bottom: 140px;

    &::after {
        content: '';
        width: 460px;
        height: 38px;
        background-image: url('../assets/icon/warranty_border.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        display: block;
    }

    &::before {
        content: '';
        width: 423px;
        height: 105px;
        background-image: url('../assets/icon/video_btn.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -69px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    .video_item {
        width: 532px;
        height: 277px;
        display: inline-block;
        vertical-align: top;
        margin-right: 35px;
        margin-top: 60px;

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        &:nth-of-type(-n+2) {
            margin-top: 0;
        }

    }

    .video_nav {
        width: 532px;
        height: 277px;
        display: block;

        position: relative;

        video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 8px;
            position: relative;
            z-index: 1;
        }

        .play {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }

}

.product_nav {
    background-color: #ECEEEE;
    width: 100%;
    background-image: url('../assets/icon/bottom_bg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% calc(100vw / 1920 * 680);
    padding-top: 130px;
    padding-bottom: 150px;

    i {
        width: 554px;
        height: 57px;
        background-image: url('../assets/icon/title.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50% 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .info_nav {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.11);
        border-radius: 30px;
        margin-top: 75px;
        padding: 75px 78px 50px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            display: block;
        }
    }

    .img1 {
        width: 755px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
    }
}

.mt50 {
    margin-top: 50px;
}

.lh19 {
    line-height: 19px;
}

.bottom_img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    margin-top: -233px;
}
</style>
