<!--
 * @author: JJ
 * @since: 2024-11-12
 * ContactUs.vue
-->
<template>
    <div class="container">
        <Header></Header>
        <section class="main col1">
            <div class="w1270 pt30 pb70">
                <div class="lh18">
                  <div class="before_link"> <router-link  to="/" >Home /</router-link></div>
                    <div class="now_link"> <router-link  to="/ContactUs" >Contact Us</router-link></div>
                </div>
                <div class="fs40 lh40 fwb pt65">Contact Us</div>
                <section class="pc">
                    <div class="box flex">
                        <div class="left">
                            <div class="item">
                                <div class="fwb fs20 lh20">Telephone</div>
                                <div class="mt30 fs16 lh16 col3">
                                    <span class="col89">Service Hotline</span>
                                    <span class="pl20">{{info.service_hotline}}</span>
                                </div>
                                <div class="mt20 fs16 lh16 col3">
                                    <span class="col89">Contact Number</span>
                                    <span class="pl20">{{info.contact_number}}</span>
                                </div>
                            </div>
                            <div class="item pt50">
                                <div class="fwb fs20 lh20">Email</div>
                                <div class="mt30 fs16 lh16 col3">
                                    <span class="col89">Enterprise Email</span>
                                    <span class="pl20">{{info.enterprise_email}}</span>
                                </div>
                            </div>
                            <div class="item pt50">
                                <div class="fwb fs20 lh20">Contact Address</div>
                                <div class="mt30 fs16 lh26 col3">
                                  {{info.contact_address}}
                                </div>
                            </div>
                        </div>
                        <div class="right flex-grow-1">
                            <div class="fwb fs20 lh20">Request Information</div>
                            <div class="pt10">
                                <input type="text" v-model="username" class="inp_nav fs16" placeholder="*Name">
                                <input type="text" v-model="phone" class="inp_nav fs16" placeholder="*Phone">
                                <input type="text" v-model="email" class="inp_nav fs16" placeholder="*E-mail">
                                <input type="text" v-model="country"  class="inp_nav fs16" placeholder="*Country">
                                <textarea v-model="content" class="textarea fs16" placeholder="*Message"></textarea>
                                <button class="submit_btn" @click="saveConactInfo">Submit</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="mobile">
                    <div class="box">
                        <div class="left">
                            <div class="item">
                                <div class="fwb fs20 lh20">Telephone</div>
                                <div class="mt15 fs16 lh16 col3">
                                    <span class="col89">Service Hotline</span>
                                    <span class="pl20">{{info.service_hotline}}</span>
                                </div>
                                <div class="mt10 fs16 lh16 col3">
                                    <span class="col89">Contact Number</span>
                                    <span class="pl20">{{info.contact_number}}</span>
                                </div>
                            </div>
                            <div class="item pt20">
                                <div class="fwb fs20 lh20">Email</div>
                                <div class="mt15 fs16 lh16 col3">
                                    <span class="col89">Enterprise Email</span>
                                    <span class="pl20">{{info.enterprise_email}}</span>
                                </div>
                            </div>
                            <div class="item pt20">
                                <div class="fwb fs20 lh20">Contact Address</div>
                                <div class="mt15 fs16 lh26 col3">
                                  {{info.contact_address}}
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="fwb fs20 lh20">Request Information</div>
                            <div class="pt10">
                                <input type="text" v-model="username" class="inp_nav fs16" placeholder="*Name">
                                <input type="text" v-model="phone" class="inp_nav fs16" placeholder="*Phone">
                                <input type="text" v-model="email" class="inp_nav fs16" placeholder="*E-mail">
                                <input type="text" v-model="country" class="inp_nav fs16" placeholder="*Country">
                                <textarea v-model="content" class="textarea fs16" placeholder="*Message"></textarea>
                                <button class="submit_btn" @click="saveConactInfo">Submit</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onMounted, ref, computed, onBeforeMount} from "vue"
import {apiArticle, apiConactInfo, submitConactInfo} from "../request/api";
import {useHead} from "@unhead/vue";
const a = ref(0);
let info=ref({service_hotline:'',contact_number:'',enterprise_email:'',contact_address:''})
let username=ref('');
let phone= ref('');
let email =ref('');
let  country=ref('');
let content=ref('');

onMounted(() => {
  getInfo()
});
onBeforeMount(()=>{
  getInfos()
})

const getInfos = async () => {
  try {

    const res = await apiArticle({title:'Contact Us'}); // 调用接口



    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const getInfo = async () => {
  try {
    const info1 = localStorage.getItem('infos');
    if (info1) {
      info.value = JSON.parse(info1);
    }
    const res = await apiConactInfo(); // 调用接口

    localStorage.setItem('infos', JSON.stringify(res.data));
    info.value = res.data;
  } catch (error) {
    console.error(error);
  }
}
const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

const saveConactInfo = async () => {
  try {

    if(username.value==''){
      alert('Please fill in your name');
      return;
    }
    if(phone.value==''){
      alert('Please fill in your phone');
      return;
    }
    if(email.value==''){
      alert('Please fill in your email');
      return;
    }
    if(!emailRegex.test(email.value.trim())){
      alert('Please fill in the correct email address.');
      return;
    }
    if(country.value==''){
      alert('Please fill in your country');
      return;
    }
    if(content.value==''){
      alert('Please fill in your message');
      return;
    }

    let data = {
      username: username.value,
      phone:phone.value,
      email: email.value,
      country: country.value,
      content: content.value,
    };

    const res = await submitConactInfo(data); // 调用接口


    alert(res.msg)
    if (res.code == 1) {
        username.value='';
        phone.value= '';
        email.value ='';
        country.value='';
        content.value='';

    }
  } catch (error) {

    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: visible;
}

.mobile {
    height: 0;
    overflow: hidden;
}
.main {
    width: 100vw;
    min-height: 985px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/icon/contact_bg.png');
}

.box {
    width: 1270px;
    min-height: 654px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-top: 50px;
    padding-top: 70px;
    padding-bottom: 70px;

    .left {
        width: 510px;
        padding-left: 100px;

        .item {
            width: 340px;
            border-bottom: 1px solid #EEEEEE;
            padding-bottom: 40px;
        }
    }

    .right {
        position: relative;
        padding-left: 60px;

        &::before {
            content: '';
            width: 1px;
            height: 515px;
            background: #EEEEEE;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            position: absolute;
        }

        .inp_nav {
            width: 290px;
            height: 55px;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 53px;
            color: #101010;
            border-radius: 18px;
            border: 1px solid #333333;
            display: inline-block;
            vertical-align: top;
            margin-top: 20px;
            margin-right: 20px;
        }

        .textarea {
            width: 600px;
            height: 193px;
            border-radius: 18px;
            border: 1px solid #333333;
            margin-top: 20px;
            padding: 15px 20px;
            resize: none;
            color: #101010;
        }

        .submit_btn {
            margin-top: 40px;
            width: 180px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #FFFFFF;
            background: #FB471F;
            border-radius: 18px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 1280px) {
    .box {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    min-height: 654px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-top: 50px;
    padding-top: 70px;
    padding-bottom: 70px;

    .left {
        width: 510px;
        padding-left: 100px;

        .item {
            width: 340px;
            border-bottom: 1px solid #EEEEEE;
            padding-bottom: 40px;
        }
    }

    .right {
        position: relative;
        padding-left: 60px;

        &::before {
            content: '';
            width: 1px;
            height: 515px;
            background: #EEEEEE;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            position: absolute;
        }

        .inp_nav {
            width: 290px;
            height: 55px;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 53px;
            color: #101010;
            border-radius: 18px;
            border: 1px solid #333333;
            display: inline-block;
            vertical-align: top;
            margin-top: 20px;
            margin-right: 20px;
        }

        .textarea {
            width: 290px;
            height: 193px;
            border-radius: 18px;
            border: 1px solid #333333;
            margin-top: 20px;
            padding: 15px 20px;
            resize: none;
            color: #101010;
        }

        .submit_btn {
            margin-top: 40px;
            width: 180px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #FFFFFF;
            background: #FB471F;
            border-radius: 18px;
            cursor: pointer;
        }
    }
}
}
@media screen and (max-width: 980px) {
    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }
    .box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    min-height: 654px;
    background: #FFFFFF;
    box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 30px;
    padding-bottom: 30px;

    .left {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        .item {
            width: 100%;
            border-bottom: 1px solid #EEEEEE;
            padding-bottom: 20px;
        }
    }

    .right {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;

        &::before {
            content: '';
            width: 1px;
            height: 515px;
            background: #EEEEEE;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            position: absolute;
            display: none;
        }

        .inp_nav {
            width: 290px;
            height: 55px;
            padding-left: 10px;
            padding-right: 10px;
            line-height: 53px;
            color: #101010;
            border-radius: 18px;
            border: 1px solid #333333;
            display: inline-block;
            vertical-align: top;
            margin-top: 20px;
            margin-right: 0px;
        }

        .textarea {
            width: 290px;
            height: 193px;
            border-radius: 18px;
            border: 1px solid #333333;
            margin-top: 20px;
            padding: 15px 10px;
            resize: none;
            color: #101010;
        }

        .submit_btn {
            margin-top: 20px;
            width: 180px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #FFFFFF;
            background: #FB471F;
            border-radius: 18px;
            cursor: pointer;
        }
    }
}
}
</style>
