<!--
 * @author: JJ
 * @since: 2024-11-11
 * Collection.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header></Header>
        <section>
            <img :src="detail.image" class="blog_banner" alt="">
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr lh18">
                <div>
                    <div class="before_link"> <router-link to="/">Home /</router-link></div>
                    <div class="before_link"><router-link to="/PartnerSearch">Network /</router-link></div>
                    <div class="now_link"> <router-link to="/Collection">Collection</router-link></div>
                </div>
                <div class="pt65 fwb fs40 col1 lh40">video</div>
                <section class="pc">
                    <div class="pt60 flex-box video_link pb80">
                        <div class="video_nav" v-if="detail.mini_image">

                            <video :src="detail.mini_image" v-if="detail.mini_image" @play="onPlay" @pause="onPause"
                                :controls="controls" ref="myVideo1"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo" class="play"
                                alt="">
                        </div>
                        <div class="flex-grow-1 pl50">
                            <p class="fs30">{{ detail.name }}</p>
                            <span class="fs16" v-html="detail.content">
                            </span>
                        </div>
                    </div>
                </section>
                <section class="mobile">
                    <div class="pt30 video_link pb40">
                        <div class="video_nav">
                            <video :src="detail.mini_image" v-if="detail.mini_image" @play="onPlay" @pause="onPause"
                                :controls="controls" ref="myVideo"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo1" class="play"
                                alt="">
                        </div>
                        <div class="mt30">
                            <p class="fs30">{{ detail.name }}</p>
                            <span class="fs16" v-html="detail.content">
                            </span>
                        </div>
                    </div>
                </section>
            </div>
            <section class="pc">
                <div class="bgf3 ptb80 col1">
                    <div class="w1270 flex-box">
                        <div class="flex-grow-1 pr50">
                            <p class="fwb fs40 col1 lh40">Picture</p>
                            <div class="pt60">
                                <p class="fs30 lh30">{{ detail.sub_name }}</p>
                                <div class="mt30 fs16 lh26" v-html="detail.resource">
                                </div>
                            </div>
                        </div>
                        <div class="w766">
                            <img v-for="(item, index) in detail.images_arr" :key="index" :src="item" class="picture_img"
                                alt="">
                            <!--                            <img src="../assets/icon/blog_news_cover.png" class="picture_img" alt="">-->
                            <!--                            <img src="../assets/icon/blog_news_cover.png" class="picture_img" alt="">-->
                            <!--                            <img src="../assets/icon/blog_news_cover.png" class="picture_img" alt="">-->
                        </div>
                    </div>
                </div>
            </section>
            <section class="mobile">
                <div class="bgf3 ptb80 col1">
                    <div class="w1270">
                        <div class="">
                            <p class="fwb fs40 col1 lh40">Picture</p>
                            <div class="pt60">
                                <p class="fs30 lh30">{{ detail.sub_name }}</p>
                                <div class="mt30 fs16 lh26" v-html="detail.resource">
                                </div>
                            </div>
                        </div>
                        <div class="w766">
                            <img v-for="(item, index) in detail.images_arr" :key="index" :src="item" class="picture_img"
                                alt="">
                            <!--                            <img src="../assets/icon/blog_news_cover.png" class="picture_img" alt="">-->
                            <!--                            <img src="../assets/icon/blog_news_cover.png" class="picture_img" alt="">-->
                            <!--                            <img src="../assets/icon/blog_news_cover.png" class="picture_img" alt="">-->
                        </div>
                    </div>
                </div>
            </section>
            <div class="case_bg ptb80">
                <div class="w1270">
                    <div class="fwb fs40 col1">Related Cases</div>
                    <ul class="w1270" id="pic">
                        <li class="picture_li fs20 lh24 col1" v-for="(item, index) in detail.cases" :key="index">
                            <router-link :to="{ name: 'ExampleInfo', query: { id: item.id, type: 2 } }">
                                <div class="img_nav">
                                    <img :src="item.image" alt="">
                                </div>
                                <div class="mt20 m-ellipsis-l2">{{ item.title }}</div>
                            </router-link>

                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from "vue"
import { apiArticle, apiGetAboutUs } from "../request/api";
import { useHead } from "@unhead/vue";
const detail = ref(null);

onBeforeMount(() => {
    getInfos()
})

const getInfos = async () => {
    try {

        const res = await apiArticle({ title: 'Collection' }); // 调用接口

        detail.value = res.data;

        useHead({
            title: res.data.sub_title,
            meta: [
                {
                    name: "description",
                    content: res.data.desc,
                },
                {
                    name: "keywords",
                    content: res.data.keywords,
                },
            ],
        });
    } catch (error) {
        console.error(error);
    }
}

const operateVideo = () => {
    if (myVideo1.value) {

        if (controls.value) {
            //
            myVideo1.value.pause();
            controls.value = false;
        } else {
            myVideo1.value.play();

            controls.value = true;
        }
    }

}
const operateVideo1 = () => {
    if (myVideo.value) {

        if (controls.value) {
            //
            myVideo.value.pause();
            controls.value = false;
        } else {
            myVideo.value.play();

            controls.value = true;
        }
    }

}
const onPlay = () => {
    controls.value = true;
    console.log('Video is playing');
};

const onPause = () => {
    controls.value = false;
    console.log('Video is paused');
};
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.video_link {
    p {
        color: #101010;
        line-height: 30px;
    }

    span {
        margin-top: 30px;
        color: #101010;
        line-height: 26px;
        display: block;
    }

    .video_nav {
        position: relative;
        width: 725px;
        height: 450px;
        border-radius: 10px;

        video {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            border-radius: 10px;
        }

        .play {
            width: 65px;
            height: 45px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }
}

.bgf3 {
    background: #F3F7FF;
}

.w766 {
    max-width: 766px;
}

.picture_img {
    width: 374px;
    height: 247px;
    background: #D8D8D8;
    border-radius: 6px;
    object-fit: cover;
    display: inline-block;
    vertical-align: top;
    margin-right: 18px;
    margin-top: 18px;

    &:nth-of-type(-n+2) {
        margin-top: 0;
    }

    &:nth-of-type(2n) {
        margin-right: 0;
    }
}

.pr50 {
    padding-right: 50px;
}

.case_bg {
    background: #ffffff;
}

.picture_li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 400px;
    margin-right: 35px;
    font-family: Metropolis-Medium;
    color: #101010;

    &:hover {
        color: #FB471F;
    }

    .img_nav {
        width: 400px;
        height: 243px;
        overflow: hidden;
        border-radius: 7px;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 7px;
        transition: all 0.6s;
    }

    img:hover {
        transform: scale(1.4);
    }
}

.picture_li:nth-of-type(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1280px) {
    .video_link {
        .video_nav {
            width: calc(725px * 0.6);
            height: calc(450px * 0.6);

        }
    }

    .gard_nav {
        width: 100%;
        padding: 70px 0 30px;
        background: #F3F7FF;

        .download_btn {
            width: 150px;
            height: 45px;
            background: #FB471F;
            border-radius: 12px;
            line-height: 45px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
        }

        .gard_item {
            width: 33.3%;
            height: 290px;
            position: relative;
            display: inline-flex;
            align-items: center;
            vertical-align: top;
            flex-direction: column;
            padding-top: 50px;
            text-align: center;

            .img_nav {
                width: calc(350px * 0.7);
                height: calc(190px * 0.7);
                overflow: hidden;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 7px;
            }

            div {
                max-width: calc(350px * 0.7);
            }
        }
    }

    .picture_li {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        width: 400px;
        margin-right: 35px;

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 35px;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    #pic.w1270 {
        max-width: 835px;
    }

    .w766 {
        max-width: 374px * 1.4 + 20px;
    }

    .picture_img {
        width: calc(374px * 0.7);
        height: calc(247px * 0.7);
        background: #D8D8D8;
        border-radius: 6px;
        object-fit: cover;
        display: inline-block;
        vertical-align: top;
        margin-right: 18px;
        margin-top: 18px;

        &:nth-of-type(-n+2) {
            margin-top: 0;
        }

        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    .pr50 {
        padding-right: 50px;
    }
}

@media screen and (max-width: 980px) {
    .watermark {
        width: calc(100% / 1440 * 704);
        height: auto;
        display: block;
    }

    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .video_link {
        .video_nav {
            width: 100%;
            height: calc(100% * 725 / 450);
            border-radius: 10px;
        }
    }

    .gard_nav {
        width: 100%;
        padding: 35px 0 15px;
        background: #F3F7FF;

        .download_btn {
            width: 150px;
            height: 45px;
            background: #FB471F;
            border-radius: 12px;
            line-height: 45px;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-left: auto;
            margin-right: 15px;
        }

        .gard_item {
            width: 100%;
            height: 290px;
            position: relative;
            display: inline-flex;
            align-items: center;
            vertical-align: top;
            flex-direction: column;
            padding-top: 50px;
            text-align: center;

            .img_nav {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 7px;
            }

            img {
                width: 100%;
                height: 190px;
                object-fit: cover;
                display: block;
                border-radius: 7px;
            }

            div {
                max-width: 100%;
            }
        }

        .gard_item::before {
            display: none;
        }

        .gard_item:nth-of-type(-n+3):after {
            display: block;
        }

        .gard_item:first-child:after {
            display: none;
        }

        .gard_item .dot1 {
            display: none;
        }

        .gard_item .dot2 {
            display: none;
        }
    }

    .picture_li {
        display: block;
        margin-top: 40px;
        width: 100%;
        margin-right: 0;

        .img_nav {
            width: 100%;
            height: 243px;
            overflow: hidden;
            border-radius: 7px;
        }

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 0;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    .w766 {
        max-width: 100%;
    }

    .picture_img {
        width: calc(374px * 0.4);
        height: calc(247px * 0.4);
        background: #D8D8D8;
        border-radius: 6px;
        object-fit: cover;
        display: inline-block;
        vertical-align: top;
        margin-right: 18px;
        margin-top: 18px;

        &:nth-of-type(-n+2) {
            margin-top: 18px;
        }

        &:nth-of-type(2n) {
            margin-right: 18px;
        }
    }
}
</style>
