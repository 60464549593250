<!--
 * @author: JJ
 * @since: 2024-11-11
 * ToolsInfo.vue
-->
<template>
    <div class="container" v-if="detail">
        <Header></Header>
        <section>
            <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
            <div class="w1270 mt_10 pr pb70">
                <div class=" lh18">
                  <div class="before_link"> <router-link  to="/" >Home /</router-link></div>
                  <div class="before_link"><router-link to="/PPF">Products /</router-link></div>
                    <div class="before_link"> <router-link to="/Tools">Tools </router-link></div>
<!--                    <div class="now_link">{{detail.goods_name}}</div>-->
                </div>
                <div class="pt65 fwb fs40 col1 lh40">Tool Details</div>
                <section class="pc">
                    <div class="pt60 flex-box video_link">
                        <div class="video_nav">
                            <video
                                :src="detail.video"
                                @play="onPlay"
                                @pause="onPause"
                                :controls="controls" ref="myVideo1"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo" class="play"
                                alt="">
                        </div>
                        <div class="flex-grow-1 pl35">
                            <p class="fs30">{{detail.goods_name}}</p>
                            <span class="fs16" v-html="detail.content">

                            </span>
                        </div>
                    </div>
                    <div class="pt70 flex-box video_link">
                        <div class="flex-grow-1 pr35">
                            <p class="fs30">{{detail.sub_name}}</p>
                            <span class="fs16" v-html="detail.tools_content">

                            </span>
                        </div>

                        <img :src="detail.image" class="tools_img" alt="">

                    </div>

                    <div class="fwb fs40 col1 pt90">Related pictures</div>
                    <ul class="w1270" id="pic">
                        <li class="picture_li" v-for="(item, index) in detail.images_arr"  :key="index">
                            <img :src="item" alt="">
                        </li>
                    </ul>
                </section>
                <section class="mobile">
                    <div class="pt30 video_link">
                        <div class="video_nav">
                            <video
                                :src="detail.video"
                                @play="onPlay"
                                @pause="onPause"
                                :controls="controls" ref="myVideo"></video>
                            <img src="../assets/icon/play.png" v-if="!controls" @click="operateVideo1" class="play"
                                alt="">
                        </div>
                        <div class="mt30">

                          <p class="fs30">{{detail.goods_name}}</p>
                          <span class="fs16" v-html="detail.content"></span>
                        </div>
                    </div>
                    <div class="pt70 video_link">
                        <img :src="detail.image" class="tools_img" alt="">
                        <div class="mt30">
                          <p class="fs30">{{detail.sub_name}}</p>
                          <span class="fs16" v-html="detail.tools_content">

                            </span>
                        </div>

                       

                    </div>

                    <div class="fwb fs40 col1 pt30">Related pictures</div>
                    <ul class="w1270" id="pic">
                        <li class="picture_li" v-for="(item, index) in detail.images_arr" :key="index">
                            <img :src="item" alt="">
                        </li>
                    </ul>
                </section>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script setup>
import {onBeforeMount, ref} from "vue"
import {apiGetToolDetail} from "../request/api";
import {useHead} from "@unhead/vue";
import { useRouter } from 'vue-router';
const router = useRouter();
let controls = ref(false)
let myVideo1 = ref(null)
let myVideo = ref(null)

const detail=ref(null);
const features=ref([]);
onBeforeMount(()=>{
  getInfos()
})
const params=router.currentRoute.value.query;


const id=ref(0);
if(params.id){
  id.value=params.id;
}

const getInfos = async () => {
  try {


    const res = await apiGetToolDetail({id:id.value}); // 调用接口
    if(res.code!=1){
      return
    }

    detail.value=res.data;


    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}


const operateVideo = () => {
  if(myVideo1.value){

    if (controls.value) {
      //
      myVideo1.value.pause();
      controls.value =false;
    } else {
      myVideo1.value.play();

      controls.value = true;
    }
  }

}
const operateVideo1 = () => {
  if(myVideo.value){

    if (controls.value) {
      //
      myVideo.value.pause();
      controls.value =false;
    } else {
      myVideo.value.play();

      controls.value = true;
    }
  }

}
const onPlay = () => {
  controls.value = true;
  console.log('Video is playing');
};

const onPause = () => {
  controls.value = false;
  console.log('Video is paused');
};
</script>
<style lang="less" scoped>
.pc {
    height: auto;
    overflow: auto;
}

.mobile {
    height: 0;
    overflow: hidden;
}

.video_link {
    p {
        color: #101010;
        line-height: 30px;
    }

    span {
        margin-top: 40px;
        color: #101010;
        line-height: 26px;
        display: block;
    }

    .tools_img {
        position: relative;
        width: 620px;
        height: 418px;
        border-radius: 10px;
        display: block;
        object-fit: cover;
    }

    .video_nav {
        position: relative;
        width: 620px;
        height: 418px;
        border-radius: 10px;

        video {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            border-radius: 10px;
        }

        .play {
            width: 65px;
            height: 45px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            z-index: 2;
        }
    }
}

.picture_li {
    display: inline-block;
    vertical-align: top;
    margin-top: 40px;
    width: 400px;
    margin-right: 35px;
    border-radius: 7px;
overflow: hidden;
    img {
        width: 100%;
        height: 243px;
        display: block;
        object-fit: cover;
        border-radius: 7px;
        transition: all 0.6s;
    }
    img:hover{
        transform: scale(1.4);
    }
}

.picture_li:nth-of-type(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1280px) {
    .picture_li {
        display: inline-block;
        vertical-align: top;
        margin-top: 40px;
        width: 400px;
        margin-right: 35px;

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 35px;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    #pic.w1270 {
        max-width: 835px;
    }
}

@media screen and (max-width: 980px) {
    .mobile {
        height: auto;
        overflow: auto;
    }

    .pc {
        height: 0;
        overflow: hidden;
    }

    .w1270 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .mt60 {
        margin-top: 30px;
    }

    .w1000 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .w1027 {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }

    .mt80 {
        margin-top: 40px;
    }

    .pt60 {
        padding-top: 30px;
    }

    .pt70 {
        padding-top: 45px;
    }

    .pt65 {
        padding-top: 35px;
    }

    .watermark {
        width: calc(100% / 1440 * 704);
        height: auto;
        display: block;
    }

    .mt_10 {
        margin-top: 10px;
    }

    .picture_li {
        display: block;
        margin-top: 40px;
        width: 100%;
        margin-right: 0;

        img {
            width: 100%;
            height: 243px;
            display: block;
            object-fit: cover;
        }
    }

    .picture_li:nth-of-type(3n) {
        margin-right: 0;
    }

    .picture_li:nth-of-type(2n) {
        margin-right: 0px;
    }

    .video_link {
        p {
            color: #101010;
            line-height: 30px;
        }

        span {
            margin-top: 20px;
            color: #101010;
            line-height: 26px;
            display: block;
        }

        .tools_img {
            position: relative;
            width: 100%;
            height:calc(100% / 620 * 418);
            border-radius: 10px;
            display: block;
            object-fit: cover;
        }

        .video_nav {
            position: relative;
            width: 100%;
            height:calc(100% / 620 * 418);
            border-radius: 10px;

            video {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
                border-radius: 10px;
            }

            .play {
                width: 65px;
                height: 45px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                cursor: pointer;
                z-index: 2;
            }
        }
    }
}
</style>
