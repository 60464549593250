<!--
 * @author: JJ
 * @since: 2024-11-12
 * Login.vue
-->
<template>
  <div class="container bg-normal">
    <header>
      <div class="header_nav flex-box flex-end">
        <div class="more flex-box flex-center" @click="showpop = true">
          <img src="../assets/icon/icon_more.png" alt="">
        </div>
      </div>
    </header>

    <div class="popup-mask" :class="showpop ? 'popup-mask_visible' : ''" @click="showpop = false"></div>
    <div class="popup-wrap" :class="showpop ? 'popup-wrap_visible' : ''">
      <div class="popup ">


        <div class="right">
          <ul class="col6a fw500">

            <li :class="index == choosepopindex ? 'active' : 'fs15 lh16'" v-for="(item, index) in popleft"
                @mouseenter="choosepop(index, 0)" :key="index">
              <router-link :to="item.url" class="flex-grow-1 cp" v-if="item.url != '/DealerPortal'">{{ item.name
                }}</router-link>
              <router-link :to="item.url" class="flex-grow-1 cp" v-if="item.url == '/DealerPortal' && shop_info">{{
                  item.name }}</router-link>
              <div v-if="item.url == '/DealerPortal' && !shop_info">{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template v-if="detail">
    <section class="main flex-box flex-col" v-if="detail.is_expire==0">
      <img src="../assets/icon/login_logo.png" class="logo" alt="">
      <p class="fwb pt80 fs24 colf_8 lh24">FORGOT YOUR PASSWORD</p>
      <p class="pt10 fs16 colf_6">Enter your new password below</p>
      <input type="text" v-model="password" class="inp_nav fs16 mt20" placeholder="PASSWORD">
      <button class="submit_btn" @click="submits">SUBMIT</button>
    </section>
      <section class="main flex-box flex-col" v-else>
        <img src="../assets/icon/login_logo.png" class="logo" alt="">
        <p class="fwb pt80 fs24 colf_8 lh24">The page has expired</p>

      </section>
    </template>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import {onBeforeMount, onMounted, ref} from "vue";
const router = useRouter();
import {useHead} from "@unhead/vue";
import {apiBaseInfomation, apiForgetPwd, apigetinfos, apigetLinkExpire, apiresetPwd} from "../request/api";
const params=router.currentRoute.value.params;
const codes=ref('');
if(params.code){
  codes.value=params.code;
}
onBeforeMount(() => {
  getInfo()
  getInfos()
  getshopinfos()
})
const shop_info=ref(null);
const getshopinfos= async ()=>{
  const res = await apigetinfos({});


  shop_info.value=res.data.login?res.data.info:null;

}
let showpop = ref(false);


let detail=ref(null);

const password=ref('')

const choosepopindex = ref(-1)
const popleft = ref([{ name: 'HOME', url: '/' },
  { name: 'ABOUT JOYVIE', url: '/blog', children: [{ 'name': 'Blog', url: '/blog' }, { name: 'About Us', url: '/aboutUs' }, { name: 'Core Values', url: '/coreValues' }] },
  { name: 'PRODUCTS', url: '/PPF', children: [{ name: 'PPF', url: '/PPF' }, { name: 'Precut Software', url: '/Software' }, { name: 'Plotter', url: '/Plotter' }, { name: 'Tools', url: '/Tools' }] },
  { name: 'TECHNICAL CENTER', url: '/WarrantyCheck', children: [{ name: 'Warranty Check', url: '/WarrantyCheck' }, { name: 'FAQ', url: '/FAQ' }, { name: 'Download Center', url: '/DownloadCenter' }] },
  { name: 'NETWORK', url: '/PartnerSearch', children: [{ name: 'Partner Search', url: '/PartnerSearch' }, { name: 'Become Partner', url: '/BecomePartner' }, { name: 'Collection', url: '/Collection' }] },
  { name: 'CONTACT US', url: '/ContactUs', children: [] },
  { name: 'DEALER PORTAL', url: '/DealerPortal', children: [] },
])
const choosepop = (index, is_click) => {
  choosepopindex.value = index;

  showpop.value = true;


}
const submits = async () => {
  try {


    if(password.value==''){
      alert('Please fill in the email.');
      return;
    }



    let data = {
    password:password.value,
      code:codes.value
    };

    const res = await apiresetPwd(data); // 调用接口


    alert(res.msg)
    if (res.code == 1) {

      router.push({ path:'/' });
    }else{
      getInfo();
    }
  } catch (error) {

    console.error(error);
  }

}
const getInfo = async () => {
  try {

    const res = await apigetLinkExpire({code:codes.value}); // 调用接口

    detail.value=res.data;
  } catch (error) {
    console.error(error);
  }
}
const getInfos = async () => {
  try {

    const res = await apiBaseInfomation(); // 调用接口

    useHead({
      title: res.data.title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.header_nav {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  padding-left: 65px;

  .logo {
    width: 102px;
    height: auto;
    cursor: pointer;
  }

  .more {
    width: 60px;
    height: 60px;
    background: #FB471F;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
    }
  }

}

/*弹窗*/
.popup-wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  transition: all 0.4s ease;
  opacity: 1;
  overflow: hidden;
  width: 0;
  transform: none;
}

.popup-wrap.popup-wrap_visible {
  width: 310px;
  transition: all 0.4s ease;
  transform: none;
}

.popup-mask {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.popup {
  width: 310px;
  height: 100vh;
  font-family: "Metropolis-Medium";

  .close {
    width: 20px;
    height: auto;
    z-index: 2;
    top: 19px;
    right: 19px;
    cursor: pointer;
    transition: all .25s ease 0s;
    transform: scaleY(0);

    &.more_ani {
      animation-delay: 1s;
      transform: scaleY(1);
      transition-duration: 0s;
    }
  }

  .left {
    width: 310px;
    height: 100vh;
    background: #333D57;
    display: inline-block;
    padding-top: 295px;
    text-align: center;
    vertical-align: top;

  }

  .right {
    width: 310px;
    height: 100vh;
    // display: inline-block;
    background: #28334E;
    padding-top: 163px;
    text-align: center;
    vertical-align: top;
    float: right;

  }

  li {
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
  }

  li.active {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
  }

  li.active::after {
    content: '';
    width: 1px;
    height: 11px;
    background: #FFFFFF;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translateY(-50%);
  }

  li:hover {
    color: #FFFFFF;
  }

  li:hover::after {
    content: '';
    width: 1px;
    height: 11px;
    background: #FFFFFF;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translateY(-50%);
  }

  li+li {
    margin-top: 30px;
  }
}

.pt100 {
  padding-top: 8%;
}

.main {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  min-height: 900px;
  background-image: url('../assets/icon/login_bg.png');
  padding-top: 144px;
  padding-bottom: 30px;
  .logo {
    width: 202px;
    height: auto;
  }

  .inp_nav {
    width: 400px;
    height: 65px;
    line-height: 65px;
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    color: #101010;
  }

  .submit_btn {
    width: 400px;
    height: 65px;
    line-height: 65px;
    background: #FB471F;
    border-radius: 18px;
    margin-top: 40px;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
  }
  .forget{
    margin-top: 90px;
    color: rgba(255,255,255,0.6);
    &:hover{color: #FB471F;font-weight: 500;font-family: Metropolis-Medium;}
    cursor: pointer;
    &:last-child{margin-top: 40px;}
  }
}

input::placeholder {
  color: #AAAAAA;
}
@media screen and (max-width: 980px) {
  .main {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100vw;
    min-height: 100vh;
    background-image: url('../assets/icon/login_bg.png');
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    .logo {
      width: 202px;
      height: auto;
    }

    .inp_nav {
      width: 100%;
      height: 65px;
      line-height: 65px;
      padding: 0 20px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 18px;
      color: #101010;
    }

    .submit_btn {
      width: 100%;
      height: 65px;
      line-height: 65px;
      background: #FB471F;
      border-radius: 18px;
      margin-top: 40px;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
    }
  }
}
</style>
