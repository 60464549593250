<!--
 * @author: JJ
 * @since: 2024-11-11
 * BecomePartner.vue
-->
<template>
  <div class="container">
    <Header></Header>
    <section v-if="detail">
      <img src="../assets/icon/watermark.png" class="watermark mr0" alt="">
      <div class="w1270 mt_10 pr pb70">
        <div class=" lh18">
          <div class="before_link"> <router-link to="/">Home /</router-link></div>
          <div class="before_link"><router-link to="/DealerPortal">Dealer Portal /</router-link></div>
          <div class="now_link"><router-link to="/EditInformation">Dealer Info Maintainence</router-link></div>
        </div>
        <div class="w580 col1">
          <div class="tc fs40 fwb lh40">Edit Information</div>
          <div class="mt10 fs16 col5 lh20 tc pb20"><span v-html="subtitle"></span></div>
          <div class="search_nav fs16 flex-box">
            <div>Dealer Name</div>
            <input type="text" v-model="detail.shop_name" class="flex-grow-1 pl30 tr"
              placeholder="Please fill in dealer name">
          </div>
          <div class="search_nav fs16 flex-box">
            <div>Telephone</div>
            <input type="text" v-model="detail.mobile" class="flex-grow-1 pl30 tr"
              placeholder="Please fill in your telephone">
          </div>
          <div class="search_nav fs16 flex-box">
            <div>Country</div>
            <el-select v-model="detail.nation_id" @change="getCitys" placeholder="Please select  your Country"
              class="custom-select flex-grow-1">
              <el-option v-for="item in nations" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
          <div class="search_nav fs16 flex-box">
            <div>Region</div>
            <el-select v-model="detail.city_id" placeholder="Please select  your Region"
              class="custom-select flex-grow-1">
              <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </div>
          <div class="search_nav fs16 flex-box">
            <div>Address</div>
            <input type="text" v-model="detail.address" class="flex-grow-1 pl30 tr"
              placeholder="Please fill in address">
          </div>
          <div class="textarea_nav fs16">
            <div>Describe</div>
            <textarea name="" v-model="detail.desc" placeholder="Please enter your description" id="" cols="30"
              rows="10"></textarea>
          </div>
          <div class="upload_box fs16">
            <div>Picture</div>
              <div class="upload_nav">
                <div class="pr" v-if="detail.shop_image">
                  <img :src="detail.shop_image" class="full_img">
                  <img src="../assets/icon/icon_del.png" @click="deleteImg" class="del" alt="">
                </div>
                <upLoad @upSuc="onUpload" v-else  class="upload_nav flex-center flex-col">

                  <img src="../assets/icon/icon_null.png" class="null" alt="">
                  <div>Upload image</div>

                </upLoad>
              </div>


          </div>
          <button class="submit_btn" @click="saveInfo">Submit</button>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, } from "vue"
import {apiCityList, apiCountryList, apiJoinUs, apiShopInfo, apiShopInfos, editShopInfo} from "../request/api";
import { useHead } from "@unhead/vue";
import { useRouter } from 'vue-router';
const router = useRouter();
import upLoad from "@/components/Upload.vue";
const subtitle = ref('Please fill in the following information carefully so that we can cooperate better')
const value = ref('')

const selectedFile = ref(null);
const nations = ref(null);
const citys = ref(null);
const image=ref('');

onBeforeMount(() => {
  getNations();
  getInfo()
})

const getNations = async () => {
  const res = await apiCountryList();

  nations.value = res.data;

}
const getCitys = async (e) => {

  const res1 = await apiCityList({ country_id: detail.value.nation_id });

  citys.value = res1.data;
}
const getCity = async (e) => {

  const res1 = await apiCityList({ country_id: detail.value.nation_id });
  detail.value.city_id = '';
  citys.value = res1.data;
}
const deleteImg=async ()=>{
  detail.value.shop_image='';
  image.value='';
}
const detail = ref(null);
const getInfo = async () => {
  try {

    const res = await apiShopInfos(); // 调用接口
    if(res.code!=1){
      if(res.code==401){
        if (confirm("please log in first")) {
          // 用户点击了确定
          router.push({ path: '/Login' });
        } else {
          // 用户点击了取消
          router.push({ path: '/' });
        }
      }
      return
    }
    image.value=res.data.image;

    detail.value = res.data;

    getCitys();
    useHead({
      title: res.data.shop_name + '_JOYVIE',
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}


let certificate = ref(null)
const onUpload = (e) => {
  certificate.value = e;
  detail.value.shop_image=e.fullurl;
  image.value=e.url;
}
const saveInfo = async () => {
  try {

    if(detail.value.shop_name==''){
      alert('Please fill in dealer name');
      return;
    }
    if(detail.value.mobile==''){
      alert('Please fill in your telephone');
      return;
    }

    if(detail.value.nation_id==''){
      alert('Please select  your country');
      return;
    }
    if(detail.value.city_id.value==''){
      alert('Please select your Region');
      return;
    }
    if(detail.value.desc==''){
      alert('Please enter your description');
      return;
    }
    if(detail.value.shop_image==''){
      alert('Please upload image');
      return;
    }


    let data = {
      shop_name: detail.value.shop_name,
      mobile:detail.value.mobile,
      nation_id: detail.value.nation_id,
      city_id:detail.value.city_id,
      desc:detail.value.desc,
      shop_image: image.value,
    };

    const res = await editShopInfo(data); // 调用接口


    alert(res.msg)
    if (res.code == 1) {
      router.push({ path: '/DealerPortal' });

    }
  } catch (error) {

    console.error(error);
  }
}

const getInfos = async () => {
  try {

    const res = await apiShopInfo({}); // 调用接口

    detail.value = res.data;

    if (res.code == 401) {
      if (confirm('Please login in first?')) {
        // 如果点击了确定，执行这里的代码
        router.push({ name: 'Login' });
      } else {
        // 如果点击了取消，执行这里的代码
        router.push({ path: '/' });
      }

    }

    useHead({
      title: res.data.shop_name + '_JOYVIE',
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.w580 {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}

.search_nav {
  margin-top: 30px;
  width: 100%;
  height: 60px;
  border-radius: 18px;
  border: 1px solid #333333;
  padding-left: 20px;
  padding-right: 20px;
}

.textarea_nav {
  width: 100%;
  height: 243px;
  border-radius: 18px;
  border: 1px solid #333333;
  padding: 20px;
  margin-top: 30px;

  textarea {
    height: 180px;
    width: 100%;
    padding-top: 20px;
    border: none;
    resize: none;
  }
}

.submit_btn {
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 260px;
  line-height: 70px;
  background: #FB471F;
  border-radius: 18px;
  margin-top: 40px;
  display: block;
}

.upload_box {
  width: 100%;
  padding: 20px 0 20px 20px;
  margin-top: 30px;
  border-radius: 18px;
  border: 1px solid #333333;

  .upload_nav {
    width: 261px;
    height: 162px;
    background: #F3F7FF;
    border-radius: 4px;
    position: relative;
    font-size: 12px;
    color: #AAAAAA;
    line-height: 12px;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    margin-right: 14px;
    margin-top: 20px;

    .null {
      width: 24px;
      height: auto;
      margin-bottom: 10px;
    }

    .full_img {
      width: 261px;
      height: 162px;
      object-fit: cover;
      display: block;
      border-radius: 4px;
    }

    .del {
      width: 12px;
      cursor: pointer;
      height: auto;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }
  }
}
</style>
<style scoped>
.custom-select {
  width: 320px;

  display: inline-block;
  vertical-align: top;
  text-align: right;


}

::v-deep.custom-select.el-select .el-select__wrapper {
  font-size: 16px;

  box-shadow: none;
  padding: 0;
  text-align: right;

  color: #333;
}

.custom-select .el-input__inner {}

.custom-select.el-select {
  --el-select-input-font-size: 16px;
  font-family: "Metropolis-Regular";
  font-weight: normal;
  --el-text-color-placeholder: #777;
  --el-text-color-regular: #333;
}
</style>