<template>
    <el-upload action="" :on-change="handleChange" :on-remove="handleRemove" :auto-upload="false" :limit="1"
        :file-list="fileList" list-type="picture-card" :show-file-list="false" :disabled="disabled">
        <slot></slot>
    </el-upload>
</template>
  
<script>
import axios from "axios";
import { ElLoading } from 'element-plus';
var loading;
export default {
    name: 'UploadImage',
    props: ['disabled'],
    components: {
        ElLoading
    },
    data() {
        return {
            fileList: [],
        };
    },
    methods: {
        handleChange(file) {
            let that = this;
            let data = new FormData();
            data.append('file', file.raw);
            const headers = {
                'Content-type': 'application/form-data'
            }
            loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(,0,0，0.7)',
            })
            axios.post(axios.defaults.baseURL + "/api/common/uploadImg", data, headers).then((res) => {
               console.log(res);
              if (res.status == 200) {

                    that.$emit('upSuc', res.data.code);
                    that.fileList = [];
                }
                loading.close();
            });
        },
        handleRemove() {
            let that = this;
            that.hideUpload = false;
        },
    },
};
</script>
  
<style scoped>
.upload-demo {
    transition: opacity 0.3s;
    /* 添加过渡效果 */
}

.image-preview {
    margin-top: 20px;
    /* 图片预览的间距 */
}

.image-preview img {
    max-width: 100%;
    /* 限制图片最大宽度 */
    height: auto;
    /* 自适应高度 */
}
</style>
<style>
.el-upload.el-upload--picture-card {
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
}
</style>