<!--
 * @author: JJ
 * @since: 2024-11-11
 * DownloadCenter.vue
-->
<template>
    <div class="container col1">
        <Header></Header>
        <section>
            <div class="w1270 pt30 pb70">
                <div class="fs14 lh18">
                  <div class="before_link"> <router-link  to="/" >Home /</router-link></div>
                  <div class="before_link"><router-link  to="/WarrantyCheck" >Technical Center /</router-link></div>
                  <div class="now_link"><router-link  to="/DownloadCenter" >Download Center</router-link></div>
                </div>
                <div class="pt65 fwb fs40 lh40">Download Center</div>
                <div class="pt50 scroll">
                        <div class="tab_item" :class="nindex == index ? 'ac' : ''" @click="chooseNav(index)"
                            v-for="(item, index) in tab" :key="index">{{ item.name}}</div>
                    </div>
                <ul class="plr20">
                    <li class="item" v-for="(item, index) in resList" :key="index">
                        <div class="cover_nav flex-box flex-center">
                            <img :src="item.image" alt="">
                        </div>
                        <p class="m-ellipsis fs16 lh16">{{item.title}}</p>
                        <a class="flex-box pt20 flex-center col4 fs14 lh14" :href="item.url" target="_blank">
                            <img src="../assets/icon/icon_download.png" class="ico12" alt="">
                            <div>Download</div>
                        </a>
                    </li>
                </ul>
            </div>
          <pagination-w v-if="total > pageSize" :sumCount="total" :perCount="pageSize"
                        @pageTurn="getList" ref="pagination"></pagination-w>
        </section>
        <Footer></Footer>

    </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, inject } from "vue";
import { useHead } from "@unhead/vue";
import {apiArticle, ApiCategoryList, ApiDownloadList} from "../request/api";
const a = ref(0)
let nindex = ref(0)
let tab = ref([])
let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据
let state = reactive({//是否显示搜索结果
  showRes: false
})
onMounted(() => {
  getCategory()
});
const getCategory = async (e) => {
  try {
    const res = await ApiCategoryList({ type: 6 });
    if (res.data.length > 0) {
      tab.value = res.data;
      //getList()
      getList();
    }

  } catch (e) {

  }
}
onBeforeMount(()=>{
  getInfo()
})
const chooseNav = (index) => {
    nindex.value = index;
    getList();
}
const getList= async (e)=>{
  try{
    const res=await ApiDownloadList({  page: e ? e + 1 : 1,  category_id: tab.value[nindex.value] ? tab.value[nindex.value].id : 0,});
    resList.value = [];
    resList.value = res.data.data;
    pageSize.value = res.data.per_page;
    total.value = res.data.total;
  }catch (e) {

  }
}
const getInfo = async () => {
  try {

    const res = await apiArticle({title:'Download Center'}); // 调用接口



    useHead({
      title: res.data.sub_title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}

</script>
<style lang="less" scoped>
.scroll{
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
}
::-webkit-scrollbar{display: none;}
.tab_item {
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
    font-weight: 400;
    font-size: 20px;
    color: #555555;
    line-height: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &.ac {
        font-weight: 500;
        color: #FB471F;
        font-family: Metropolis-Medium;
    }

    &.ac::after {
        content: '';
        width: 4px;
        height: 4px;
        background: #FB471F;
        border-radius: 12px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.item {
    display: inline-block;
    vertical-align: top;
    margin-top: 30px;
    margin-right: 30px;
    width: 220px;

    &:last-child {
        margin-right: 0;
    }

    .cover_nav {
        width: 220px;
        height: 220px;
        background: #F5F8FC;
        border-radius: 5px;

        img {
            width: 80px;
            height: auto;
        }
    }

    p {
        padding-top: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 980px) {
    .item {
    display: block;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    width: 220px;

    &:last-child {
        margin-right: auto;
    }

    .cover_nav {
        width: 220px;
        height: 220px;
        background: #F5F8FC;
        border-radius: 5px;

        img {
            width: 80px;
            height: auto;
        }
    }

    p {
        padding-top: 20px;
        text-align: center;
    }
}
}
</style>
